import { ProductsCard } from './ProductsCard';
import { FC, memo } from 'react';
import { IProduct } from '../../types/product';
import { useSortable } from '@dnd-kit/sortable';

interface IProps {
  product: IProduct;
  isActive: boolean;
  isThird?: boolean;
  hidingFunctionality?: boolean;
  href?: string;
  onClick?: (event) => void;
  onClickHideBtn?: (event) => void;
}

export const ProductsCardSortable: FC<IProps> = memo(function ProductsCardSortable(props) {
  const { product } = props;
  const sortableObject = useSortable({ id: product.id });

  return (
    <ProductsCard {...props} sortable={sortableObject} />
  );
});

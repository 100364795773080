import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useStyle } from '../../utils/theme/useStyle';
import { PromoCodeFormRules } from './PromoCodeForm.style';
import { Button } from '../UI/Button/Button';
import Text from '../UI/Text/Text';
import Toggler from '../UI/Toggler/Toggler';
import { VALIDATION_ERRORS } from '../../types/validation';
import { Input } from '../UI/Input/Input';
import { ListButton } from '../UI/ListButton/ListButton';
import { ModalUniversal } from '../ModalUniversal/ModalUniversal';
import { useModalState } from '../../hooks/useModalState';
import { Option } from '../UI/Option/Option';
import useCurrencySymbol from '../../hooks/useCurrencySymbol';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { wizardStateSelector } from '../../redux/slices/wizardSlice';
import { useFirstRender } from '../../hooks/useFirstRender';
import { useTranslation } from 'src/utils/i18n/hooks/useTranslation';
import {
  getPromoCodesList, promoCodeDelete,
  promoCodesSelector,
  promoCodeUpsert,
  resetNetworkStatus, TypeOfDiscount
} from '../../redux/slices/promoCodesSlice';
import { NetworkStatus } from '../../utils/network/network.constant';
import { OwnerPromocode } from '@teleport/schemas-protobuf/port/v1/port_promocode_pb';
import { PromoCodeTranslator } from '../../redux/translators/promoCodeTranslation';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from '../../routing/routeConfig';
import { useTheme } from '../../utils/theme/useTheme';

interface IPromoCodeForm {
  uuid: string,
  isActive: boolean;
  codeName: string;
  typeOfDiscount: TypeOfDiscount;
  discountAmount: number;
  isDisposable: boolean;
  hasMinOrderAmount: boolean;
  minOrderAmount: number;
  forFirstOrder: boolean;
}

export interface IPromoCode extends IPromoCodeForm {
  typeOfDiscount: TypeOfDiscount.PriceImpact | TypeOfDiscount.PercentImpact;
}

interface IPromoCodeFormProps {
  promoCodeUuid?: string
}

export const PromoCodeForm: FC<IPromoCodeFormProps> = memo(function PromoCodeForm(props) {
  const { promoCodeUuid } = props;
  const [footerHeight, setFooterHeight] = useState(0);
  const footerRef = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      setFooterHeight(node.clientHeight);
    }
  }, []);
  const { css } = useStyle(PromoCodeFormRules, {footerHeight});
  const { theme } = useTheme();
  const [savedSuccessfully] = useState(false);
  const { wizard } = useAppSelector(wizardStateSelector)
  const isFirstRender = useFirstRender()
  const currencySymbol = useCurrencySymbol(wizard.currency);
  const { t } = useTranslation()
  const dispatch = useAppDispatch();
  const {
    promoCodesListStatus,
    promoCodeDeleteStatus,
    upsertPromoCodeStatus,
    promoCodes
  } = useAppSelector(promoCodesSelector);
  const navigate = useNavigate();
  const [promoCodeData, setPromoCodeData] = useState<OwnerPromocode | null>(null)
  const {
    control,
    watch,
    register,
    unregister,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
    handleSubmit,
  } = useForm<IPromoCodeForm>({
    defaultValues: {
      uuid: '',
      isActive: false,
      codeName: '',
      typeOfDiscount: TypeOfDiscount.Unspecified,
      discountAmount: null,
      isDisposable: false,
      hasMinOrderAmount: false,
      minOrderAmount: null,
      forFirstOrder: false,
    },
  });

  const isEditingPromoCode = useMemo(() => {
    return Boolean(promoCodeUuid)
  }, [promoCodeUuid]) ;

  useEffect(() => {
    if (upsertPromoCodeStatus === NetworkStatus.Done) {
      navigate(RoutePath.PromoCodes);
      dispatch(resetNetworkStatus('upsertPromoCodeStatus'));
    }
    if (promoCodeDeleteStatus === NetworkStatus.Done) {
      navigate(RoutePath.PromoCodes);
      dispatch(resetNetworkStatus('promoCodeDeleteStatus'));
    }
  }, [
    dispatch,
    navigate,
    upsertPromoCodeStatus,
    promoCodeDeleteStatus
  ]);

  useEffect(() => {
    if(isEditingPromoCode && promoCodesListStatus === NetworkStatus.None) {
      dispatch(getPromoCodesList());
    }
    if (isEditingPromoCode && promoCodesListStatus === NetworkStatus.Done) {
      const promocode = promoCodes.find(el => el.promocode.uuid === promoCodeUuid);
      if (promocode) setPromoCodeData(promocode);
    }

  }, [
    dispatch,
    isEditingPromoCode,
    promoCodeUuid,
    promoCodesListStatus,
    promoCodes
  ]);

  const typesDiscounts = useMemo(() => {
    return [
      {
        title: `${t('promoCodeForm.in', currencySymbol)}`,
        value: TypeOfDiscount.PriceImpact
      },
      {
        title: `${t('promoCodeForm.in', '%')}`,
        value: TypeOfDiscount.PercentImpact
      },
    ]
  }, [currencySymbol, t])

  const [typeDiscount, setTypeDiscount] = useState({
    value: TypeOfDiscount.Unspecified,
    text: t('promoCodeForm.chooseFromTheList'),
    symbol: '',
  });
  const [discountAmountRules, setDiscountAmountRules] = useState<{
    required: string,
    max?: {
      value: number,
      message: string,
    },
    valueAsNumber: boolean
  }>({required: VALIDATION_ERRORS.REQUIRED, valueAsNumber: true});

  const discountAmountRegister = useMemo(() => {
    return register('discountAmount', discountAmountRules);
  }, [register, discountAmountRules]);

  useEffect(() => {
    if(typeDiscount.value === TypeOfDiscount.PercentImpact) {
      setDiscountAmountRules({
        required: VALIDATION_ERRORS.REQUIRED,
        max: {
          value: 100,
          message: t('promoCodeForm.cannotBeMoreThanOneHundred'),
        },
        valueAsNumber: true
      });
    } else {
      setDiscountAmountRules({
        required: VALIDATION_ERRORS.REQUIRED,
        valueAsNumber: true
      });
    }
  }, [typeDiscount, t]);

  const [renderModal, activeModal, openModal, closeModal] =
    useModalState();

  const onSubmit = (data: IPromoCodeForm) => {
    if(!data.typeOfDiscount) {
      setError('typeOfDiscount', {message: t('promoCodeForm.chooseFiscountType')})
      return;
    }
    const typeOfDiscount = data.typeOfDiscount;
    const ownerPromocode = PromoCodeTranslator.toOwnerPromoCode({...data, typeOfDiscount})
    dispatch(promoCodeUpsert(ownerPromocode));
  }

  const onClickListButton = () => {
    openModal();
  }

  const onClickDeleteButton = useCallback(
    () => {
      dispatch(promoCodeDelete(promoCodeData?.promocode.uuid))
    }, [dispatch, promoCodeData]);

  const changeTypeDiscount = useCallback((
    value: string,
    discountAmount = null
  ) => {
    let discountTypeText = '';
    let discountAmountSymbol = '';
    if (value === TypeOfDiscount.PriceImpact) {
      discountTypeText = t('promoCodeForm.inCurrency')
      discountAmountSymbol = currencySymbol;
    } else if (value === TypeOfDiscount.PercentImpact) {
      discountTypeText = t('promoCodeForm.inPercent')
      discountAmountSymbol = '%';
    } else {
      discountTypeText = t('promoCodeForm.chooseFromTheList')
    }
    setTypeDiscount({value: value as TypeOfDiscount, text: discountTypeText, symbol: discountAmountSymbol });
    setValue('typeOfDiscount', value as TypeOfDiscount);
    unregister('discountAmount');
    clearErrors('discountAmount');
    clearErrors('typeOfDiscount');
    setValue('discountAmount', discountAmount);
    closeModal();
  }, [
    t,
    clearErrors,
    closeModal,
    currencySymbol,
    setValue,
    unregister
  ]);

  useEffect(() => {

    if (promoCodeData) {
      changeTypeDiscount(
        promoCodeData.promocode.impact.case,
        promoCodeData.promocode.impact.value
      )
      setValue('uuid',promoCodeData.promocode.uuid);
      setValue('isActive', Boolean(promoCodeData.enabled));
      setValue('codeName', promoCodeData.promocode.codename);
      setValue('typeOfDiscount', promoCodeData.promocode.impact.case as TypeOfDiscount);
      setValue('discountAmount', Number(promoCodeData.promocode.impact.value.value));
      setValue('isDisposable', Boolean(promoCodeData.constraintSet.uniquePerUser));
      setValue('hasMinOrderAmount', Boolean(promoCodeData.constraintSet.minimalPriceOrder));
      setValue('minOrderAmount', Number(promoCodeData.constraintSet.minimalPriceOrder));
      setValue('forFirstOrder', Boolean(promoCodeData.constraintSet.firstOrder))
    }
  }, [
    promoCodeData,
    setValue,
    changeTypeDiscount
  ]);

  return (
    <>
      <form className={css.wrapper} onSubmit={handleSubmit(onSubmit)}>
        <div className={`${css.header} header`}>
          <Text
            text={t('promoCodeForm.promoCode')}
            mod="title"
            fontWeight={800}
            fontSize={20}
            lineHeight={22}
          />
          <Controller
            name='isActive'
            control={control}
            render={({field: {name, onChange, value}}) => (
              <Toggler
                name={name}
                checked={value}
                value="show-in-catalog"
                onChange={onChange}
              />
            )}
          />
        </div>
        <Input
          {...register('codeName', {
            required: VALIDATION_ERRORS.REQUIRED,
          })}
          controlled={false}
          label={t('promoCodeForm.codeWord')}
          type="text"
          placeholder={t('promoCodeForm.enterWord')}
          maxLength={70}
          errorMessage={errors.codeName?.message}
          disabled={isEditingPromoCode}
          labelTextTransform='uppercase'
        />
        <ListButton
          title={t('promoCodeForm.discountType')}
          onClick={onClickListButton}
          bg="transparent"
          placeholder={errors.typeOfDiscount?.message ?? typeDiscount.text}
          titleFontSize={12}
          redPlaceholder={Boolean(errors.typeOfDiscount?.message)}
        />
        <Input
          {...discountAmountRegister}
          controlled={false}
          label={t('promoCodeForm.discountAmount')}
          type="number"
          placeholder={t('promoCodeForm.enterNumber')}
          maxLength={70}
          symbol={typeDiscount.symbol}
          errorMessage={errors.discountAmount?.message}
          labelTextTransform='uppercase'
        />
        <div className={css.wrapperJustify}>
          <Text
            text={t('promoCodeForm.oneTimeUse')}
            mod="title"
            textTransform='uppercase'
            fontSize={12}
          />
          <Controller
            name='isDisposable'
            control={control}
            render={({field: {name, onChange, value}}) => (
              <Toggler
                name={name}
                value="isDisposable"
                checked={value}
                onChange={onChange}
              />
            )}
          />
        </div>
        <div className={css.wrapperJustify}>
          <Text
            text={t('promoCodeForm.minimumOrderAmount')}
            mod="title"
            textTransform='uppercase'
            fontSize={12}
          />
          <Controller
            name='hasMinOrderAmount'
            control={control}
            render={({field: {name, onChange, value}}) => (
              <Toggler
                name={name}
                value='hasMinOrderAmount'
                checked={value}
                onChange={onChange}
              />
            )}
          />
        </div>
        {watch('hasMinOrderAmount') && (
          <Input
            {...register('minOrderAmount', {
              valueAsNumber: true,
              required: VALIDATION_ERRORS.REQUIRED,
            })}
            placeholder={t('promoCodeForm.enterNumber')}
            type="number"
            controlled={false}
            autoFocus={!isFirstRender}
            errorMessage={errors.minOrderAmount?.message}
          />
        )}
        <div className={css.wrapperJustify}>
          <Text
            text={t('promoCodeForm.firstOrderOnly')}
            mod="title"
            textTransform='uppercase'
            fontSize={12}
          />
          <Controller
            name='forFirstOrder'
            control={control}
            render={({field: {name, onChange, value}}) => (
              <Toggler
                name={name}
                value='forFirstOrder'
                checked={value}
                onChange={onChange}
              />
            )}
          />
        </div>
        <div className={css.footer} ref={footerRef}>
          <Button
            text={t('promoCodeForm.savePromoCode')}
            successfully={savedSuccessfully}
            propsStyles={{width: '100%'}}
            type="submit"
          />
        </div>
        {isEditingPromoCode
          && <Button
            disabled={promoCodeDeleteStatus === NetworkStatus.Loading}
            type='button'
            text={t('promoCodeForm.delete')}
            propsStyles={{background: theme.colorGrey, color: theme.colorBlack, width: '100%'}}
            onClick={onClickDeleteButton}
            hasGradient={false}
          /> }
      </form>
      {renderModal &&
        <ModalUniversal
          active={activeModal}
          onClose={closeModal}
          title={t('promoCodeForm.discountType')}
        >
          <div className={css.optionsWrapper}>
            {typesDiscounts.map(el =>
              <Option
                key={el.value}
                value={el.value}
                title={el.title}
                checked={typeDiscount.value === el.value}
                type="radio"
                onChange={changeTypeDiscount}
              />)}
          </div>
        </ModalUniversal>
      }
    </>
  );
});

import { FC, memo, useEffect, useMemo } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { SettingCategoriesPageRules } from './SettingCategoriesPage.style';
import { Layout } from 'src/components/Layout/Layout';
import { NoSettings } from 'src/components/NoSettings/NoSettings';
import Footer from 'src/components/Footer/Footer';
import { Button } from 'src/components/UI/Button/Button';
import Text from 'src/components/UI/Text/Text';
import Container from 'src/components/Container/Container';
import { useModalState } from 'src/hooks/useModalState';
import useBackButton from 'src/hooks/useBackButton';
import { useNavigate } from 'react-router-dom';
import { useScrollToTop } from 'src/hooks/useScrollToTop';
import useLoader from 'src/hooks/useLoader';
import { ModalSettingStory } from 'src/blocks/settings-main/setting-stories/ModalSettingStory/ModalSettingStory';
import { CategoriesWrapper } from 'src/blocks/settings-main/settings-categories/CategoriesWrapper/CategoriesWrapper';
import { RoutePath } from '../../routing/routeConfig';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import { categoryState, getRootCategoryList } from 'src/redux/slices/categorySlice';
import { NetworkStatus } from '../../utils/network/network.constant';
import { useTranslation } from 'src/utils/i18n/hooks/useTranslation';

//СТРАНИЦА ВЫВОДА КАТЕГОРИЙ ИЛИ ЗАГЛУШКИ ЕСЛИ КАТЕГОРИЙ НЕТ
export const SettingCategoriesPage: FC = memo(function SettingCategoriesPage() {
  const { css } = useStyle(SettingCategoriesPageRules);
  const [renderModal, activeModal, openModal, closeModal] = useModalState();
  const BackButton = useBackButton();
  const navigate = useNavigate();
  const { hideLoader } = useLoader();
  const dispatch = useAppDispatch();
  const { categories, networkStatus } = useAppSelector(categoryState);

  const { t } = useTranslation()

  useEffect(() => {
    if (networkStatus === NetworkStatus.None) {
      dispatch(getRootCategoryList());
    }
    hideLoader();
  }, [hideLoader, dispatch, networkStatus]);

  const isCategoryListLoading =
    networkStatus === NetworkStatus.None || networkStatus === NetworkStatus.Loading;

  useScrollToTop();
  useEffect(() => {
    BackButton.onClickCustom(goBack);
    function goBack() {
      navigate('/');
    }
    return () => {
      BackButton?.offClickCustom(goBack);
    };
  }, [BackButton, navigate]);

  const content = useMemo(() => {
    if (isCategoryListLoading) {
      // todo loader
      return <div>{'Loading'}</div>;
    }

    if (categories.length > 0) {
      return <CategoriesWrapper categories={categories} />;
    }

    return (
      <NoSettings
        title={t('settingsCategoriesPage.youHaventCreatedAnyCategoriesYet')}
        text={t('settingsCategoriesPage.createCategoriesForProducts')}
        icon="categories-large"
      />
    );
  }, [categories, isCategoryListLoading, t]);

  return (
    <Layout
      header={
        <header className={`${css.header} header`}>
          <Text text={t('settingsCategoriesPage.category')} mod="title" fontWeight={800} fontSize={20} lineHeight={22}  />
        </header>
      }
      footer={
        <Footer>
          <Button
            href={RoutePath.CreateCategory}
            text={t('settingsCategoriesPage.createCategory')}
            propsStyles={{ width: '100%' }}
            onClick={openModal}
          />
        </Footer>
      }
    >
      <Container extend={css.container}>{content}</Container>
      {renderModal && <ModalSettingStory active={activeModal} onClose={closeModal} />}
    </Layout>
  );
});

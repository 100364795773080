import { FC, memo } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { StoryCardInSettingsRules } from './StoryCardInSettings.style';
import Text from 'src/components/UI/Text/Text';
import { Icon } from 'src/components/UI/Icon/Icon';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { IStory } from 'src/types/stories';

interface IProps {
  storyData: IStory;
  openEditModal: (event: any) => void;
}

export const StoryCardInSettings: FC<IProps> = memo(function StoryCardInSettings(props) {
  const { storyData, openEditModal } = props;
  const { clickUrl, imageUrl, text, id } = storyData;
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

  const { css } = useStyle(StoryCardInSettingsRules, {
    transform: CSS.Transform.toString(transform),
    transition,
  });

  return (
    <div className={css.card} id={`${id}`} {...attributes} ref={setNodeRef} onClick={openEditModal}>
      <img className={css.image} src={imageUrl} loading='lazy' alt="story-img" />
      <div className={css.info}>
        <Text extend={css.text} mod="title" text={text} fontSize={14} lineHeight={16} />
        <div className={css.linkWrap}>
          <Icon name="link" properties={{ className: css.iconLink }} />
          <Text
            mod="text"
            text={clickUrl}
            fontSize={12}
            lineHeight={16}
            fontWeight={500}
            extend={css.link}
          />
        </div>
      </div>
      <button {...listeners} className={css.buttonDrag}>
        <Icon name="bars" properties={{ className: css.iconBars }} />
      </button>
    </div>
  );
});

import { createCachedStyle } from '../../../../../utils/theme/createCachedStyle';
import { IRuleFn } from '../../../../../utils/theme/fela.interface';
import { padding } from '../../../../../utils/applyCSSProperty ';

export interface IModalModifierKeys {
  modalInner: {};
  header: {};
  footer: {};
  buttonModifier: {};
  modifiersWrapper: {};
  backButton: {};
  activeModifiers: {};
  modifiersIcon: {};
  subtitle: {};
  noModifiers: {};
  wrapper: {};
  btnActive: {};
}

interface IModalModifierProps {
  headerHeight: number;
}

export type IModalModifier = IRuleFn<IModalModifierKeys, IModalModifierProps>;

const ModalModifierRuleFn: IModalModifier = props => {
  const {sidePadding, colorWhite, colorDarkGrey, colorBlack, background, mainColor} = props.theme;
  const { headerHeight } = props;

  return {
    modalInner: {
      position: 'relative',
      paddingLeft: sidePadding,
      paddingRight: sidePadding,
      paddingBottom: 104,
      height: `calc(100% - ${headerHeight}px)`,
      overflow: 'auto',
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      columnGap: 8,
      ...padding(15, sidePadding, 20),
    },
    footer: {
      position: 'fixed',
      backgroundColor: colorWhite,
      left: 0,
      bottom: 0,
      width: '100%',
      ...padding(12,sidePadding, 30),
    },
    buttonModifier: {
      width: '100%',
      borderWidth: 2,
      borderColor: 'transparent',
      borderStyle: 'solid',
      color: colorBlack,
      outline: 'none',
      borderRadius: 12,
      backgroundColor: background,
      minHeight: 68,
      ...padding(16),
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      rowGap: 4,
      textAlign: 'start',
      ':active': {
        borderColor: mainColor
      }
    },
    btnActive: {
      borderColor: mainColor,
    },
    modifiersWrapper: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 8,
    },
    backButton: {
      width: 24,
      height: 24,
      borderRadius: '50%',
      border: 'none',
      outline: 'none',
      padding: 4,
      flexShrink: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      '& svg': {
        fill: colorBlack
      }
    },
    activeModifiers: {
      fontSize: 12,
      lineHeight: '14px',
    },
    modifiersIcon: {
      width: 50,
      height: 50,
      fill: mainColor,
      marginBottom: 16,
    },
    subtitle: {
      marginTop: 8,
      color: colorDarkGrey,
    },
    noModifiers: {
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingBottom: 10,
    },
    wrapper: {
      padding: 20,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }
  };
};

export const ModalModifierRules = createCachedStyle(ModalModifierRuleFn);

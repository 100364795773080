import { FC, memo, useEffect, useMemo } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { NoSettings } from 'src/components/NoSettings/NoSettings';
import { Layout } from 'src/components/Layout/Layout';
import Container from 'src/components/Container/Container';
import Footer from 'src/components/Footer/Footer';
import { Button } from 'src/components/UI/Button/Button';
import Text from 'src/components/UI/Text/Text';
import { SelectionProductPageRules } from './SelectionProductPage.style';
import useLoader from 'src/hooks/useLoader';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import { useNavigate, useParams } from 'react-router-dom';
import useBackButton from 'src/hooks/useBackButton';
import { useTranslation } from 'src/utils/i18n/hooks/useTranslation';
import { getSelections } from 'src/redux/api/selections/getSelections';
import { NetworkStatus } from 'src/utils/network/network.constant';
import { selectionProductReorder, selectionsState, updateNewSelection, updateNewSelectionProducts, updateSelectionProduct, updateSelectionProducts } from 'src/redux/slices/selectionsSlice';
import ModalListProducts from 'src/components/ModalListProducts/ModalListProducts';
import { useModalState } from 'src/hooks/useModalState';
import { IProduct } from 'src/types/product';
import { ISelectionProduct } from 'src/types/selection';
import { newSelectionPageName } from 'src/utils/constants';
import { productToSelectionBind } from 'src/redux/api/selections/productToSelectionBind';
import { ProductsList } from 'src/components/ProductsList/ProductsList';


interface IProps {
  test?: string;
}

export const SelectionProductPage: FC<IProps> = memo(function SelectionProductPage(props) {
  const {} = props;
  const { css } = useStyle(SelectionProductPageRules);
  const { showLoader, hideLoader } = useLoader();
  const BackButton = useBackButton();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { id } = useParams();
  const [renderModal, activeModal, openModal, closeModal] = useModalState();

  const { networkStatus, selections, newSelection } = useAppSelector(selectionsState);

  
  useEffect(() => {
    if (networkStatus === NetworkStatus.None) {
      dispatch(getSelections());
    }
    if (networkStatus === NetworkStatus.Loading ) {
      showLoader();
    }
    if (networkStatus === NetworkStatus.Done ) {
      hideLoader();
    }
  }, [networkStatus, dispatch, hideLoader, showLoader]);

  useEffect(() => {
    BackButton.onClickCustom(goBack);
    function goBack() {
      navigate(-1);
    }
    return () => {
      BackButton?.offClickCustom(goBack);
    };
  }, [BackButton, navigate]);

  const renderProducts = useMemo(() => {
    if (id === newSelectionPageName) {
      const newSelectionProducts = newSelection?.products
      return newSelectionProducts ?? []
    }else {
      if (!selections || !id) return []
      const selection = selections.find(el => el.uuid === id)
      const selectionProducts = selection?.products
      return selectionProducts ?? []
    }

  }, [selections, id, newSelection])

  const onClickProduct = event => {
    const product: IProduct = JSON.parse(event.currentTarget.dataset.product);
    const selectionProduct: ISelectionProduct = {
      uuid: product.id,
      previewImageUrls: product.imageUrls
    }
    if (id === newSelectionPageName) {
      const isProductInSelection = newSelection.products.some(p => p.id === selectionProduct.uuid);
      dispatch(updateNewSelection({
        ...newSelection,
        products: isProductInSelection
          ? newSelection.products.filter(p => p.id !== selectionProduct.uuid) 
          : [...newSelection.products, product]
      }))
    }else {
      dispatch(updateSelectionProduct({selectionUuid: id, product: product}))
    }
  }

  const openProductModal = () => {
    openModal()
  }

  const productUuids = renderProducts.map(product => product.id)

  const addProductsFunction = () => {
    const selection = selections.find(el => el.uuid === id)
    selection && dispatch(
      productToSelectionBind({
        selection: selection,
        productUuids,
      }),
    );
    closeModal()
  }

  const reorderProductFunction = (products: IProduct[]) => {
    if (id === newSelectionPageName) {
      dispatch(updateNewSelectionProducts(products))
    }else {
      dispatch(updateSelectionProducts({selectionUuid: id, products: products}))
      const selection = selections.find(el => el.uuid === id)
      dispatch(selectionProductReorder({
        selectionUuid: id,
        productUuids: selection.products.map(el => el.id)
      }));
    }

  }

  return (
    <Layout
      padding={'6px 0 0'}
      header={
        <header className={`${css.header} header`}>
          <Text
            text={t('selectionProductPage.products')}
            mod="title"
            fontWeight={800}
            fontSize={20}
            lineHeight={22}
          />
        </header>
      }
      footer={
        <Footer>
          <Button
            text={t('selectionProductPage.addProducts')}
            propsStyles={{ width: '100%' }}
            onClick={openProductModal}
          />
        </Footer>
      }
    >
      <Container extend={css.container}>
        {renderProducts?.length > 0 ? (
          <ProductsList 
            notationText={t('selectionProductPage.everyThirdCardWillBeLargeInTheStoreCatalog')}
            products={renderProducts}
            onReorderCallback={reorderProductFunction}
          />
        ) : (
          <NoSettings
            title={t('selectionProductPage.youHaventAddedAnyProductsToTheSelectionYet')}
            text={t('selectionProductPage.addProductsToTheCollection')}
            icon="products-large"
          />
        )}
      </Container>
      {renderModal && (
        <ModalListProducts
          active={activeModal}
          onClose={closeModal}
          onClickProduct={onClickProduct}
          selectedProducts={productUuids}
          onClickAddProducts={addProductsFunction}
        /> 
      )}
    </Layout>
  );
});

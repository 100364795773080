import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import Text from 'src/components/UI/Text/Text';
import useBackButton from 'src/hooks/useBackButton';
import { SettingProductModifierRules } from './SettingProductModifier.style';
import Toggler from 'src/components/UI/Toggler/Toggler';
import { Input } from 'src/components/UI/Input/Input';
import { useTheme } from '../../../../../utils/theme/useTheme';
import { Modifier } from '@teleport/schemas-protobuf';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux';
import {
  productSelector,
  setArrayForSettingModifiers,
} from '../../../../../redux/slices/productSlice';
import { useTranslation } from '../../../../../utils/i18n/hooks/useTranslation';
import { wizardStateSelector } from 'src/redux/slices/wizardSlice';
import useCurrencySymbol from 'src/hooks/useCurrencySymbol';

interface IProps {
  modifier?: Modifier;
  endSetting: () => void;
}

export const SettingProductModifier: FC<IProps> = memo(function SettingProductModifier(props) {
  const { modifier, endSetting } = props;
  const { t } = useTranslation();
  const { css } = useStyle(SettingProductModifierRules);
  const { theme } = useTheme();
  const BackButton = useBackButton();
  const [modifierData] = useState<Modifier>(modifier);
  const dispatch = useAppDispatch();
  const { arrayForSettingModifiers, modifiers: productModifiers } = useAppSelector(productSelector);
  const {
    wizard: wizardData,
  } = useAppSelector(wizardStateSelector);

  useEffect(() => {
    const arrayForSettingModifiers = modifierData.variants.map(el => ({
      modifierVariantUuid: el.uuid,
      priceImpact: 0,
      active: false,
    }));
    productModifiers.forEach(el => {
      const modifier = arrayForSettingModifiers.find(item => item.modifierVariantUuid === el.modifierVariantUuid);
      if (modifier) {
        modifier.priceImpact = Number(el.priceImpact);
        modifier.active = true;
      }
    })
    dispatch(setArrayForSettingModifiers(arrayForSettingModifiers));
  }, [dispatch, modifierData, productModifiers]);

  const currentCurrencySymbol = useCurrencySymbol(wizardData?.currency);

  useEffect(() => {
    BackButton.onClickCustom(goBack);
    function goBack() {
      endSetting();
    }
    return () => {
      BackButton?.offClickCustom(goBack);
    };
  }, [BackButton, endSetting]);

  const changeActiveModifierVariant = useCallback(event => {
    const variantUuid = event.currentTarget.value;
    const modifier = arrayForSettingModifiers.find(el => el.modifierVariantUuid === variantUuid);
    const index = arrayForSettingModifiers.indexOf(modifier);
    const newModifier = {...modifier, active: !modifier.active};
    const newState = [...arrayForSettingModifiers];
    newState.splice(index, 1, newModifier);
    dispatch(setArrayForSettingModifiers(newState));
  }, [dispatch, arrayForSettingModifiers]);

  const changeAffectPriceItem = useCallback(event => {
    const variantId = event.currentTarget.id;
    const newState = [...arrayForSettingModifiers];
    const modifierImpact = newState.find(el => el.modifierVariantUuid === variantId);
    const index = newState.indexOf(modifierImpact);
    const newModifierImpact = {...modifierImpact, priceImpact: Number(event.currentTarget.value) };
    newState.splice(index, 1, newModifierImpact);
    dispatch(setArrayForSettingModifiers(newState));
  }, [dispatch, arrayForSettingModifiers]);

  const variants = useMemo(() => {
    return modifierData.variants?.map(el => {

      let priceImpact = '0';
      const affectPriceItem = arrayForSettingModifiers.find(modifier => modifier.modifierVariantUuid === el.uuid);
      if (affectPriceItem) {
        priceImpact = `${Number(affectPriceItem.priceImpact)}`;
      }

      return (
        <div key={el.uuid} className={css.modifierItem}>
          <Toggler
            name={el.uuid}
            value={el.uuid}
            checked={Boolean(arrayForSettingModifiers.find(modifierImpact => modifierImpact.modifierVariantUuid === el.uuid)?.active)}
            onChange={changeActiveModifierVariant}
          />{' '}
          <Text
            mod="title"
            text={el.title}
            fontSize={18}
            textTransform='uppercase'
            lineHeight={18}
          />
          {modifierData.affectsPrice && (
            <div className={css.affectPrice}>
              <Input
                controlled={true}
                value={priceImpact}
                id={el.uuid}
                propsStyles={{backgroundColor: theme.background}}
                // TODO написать нормальную changeAffectPriceItem функцию
                onChange={changeAffectPriceItem}
                type="number"
                name={`affect-${el.uuid}`}
                symbol={currentCurrencySymbol}
              />
            </div>
          )}
        </div>)
    })
  }, [
    css,
    theme,
    arrayForSettingModifiers,
    changeActiveModifierVariant,
    changeAffectPriceItem,
    modifierData,
    currentCurrencySymbol,
  ])

  return (
    <div className={css.wrapper}>
      {modifierData.affectsPrice && (
        <Text
          mod="text"
          text={t('settingProductModifier.specifyHowMuchThePriceOfTheProductWillIncreaseWithTheSelectedOption')}
          fontSize={14}
          fontWeight={600}
          lineHeight={16}
          extend={css.supportText}
        />
      )}
      <div className={css.modifierItemsWrapper}>
        {variants}
      </div>
    </div>
  );
});

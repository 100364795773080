import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import Modal, { ModalNames } from '../Modal/Modal';
import { Button } from '../UI/Button/Button';
import { modalQrCodeRules } from './ModalQrCode.style';
import { QRCodeCanvas } from 'qrcode.react';
import { wizardStateSelector } from 'src/redux/slices/wizardSlice';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import { useTheme } from 'src/utils/theme/useTheme';
import { qrState, uploadQr } from 'src/redux/slices/qrSlice';
import { NetworkStatus } from 'src/utils/network/network.constant';
import { ToastComponent } from '../ToastComponent/ToastComponent';
import useCustomToast from 'src/hooks/useCustomToast ';
import { useTranslation } from 'src/utils/i18n/hooks/useTranslation';
import { configState } from 'src/redux/slices/configSlice';

interface IProps {
  onClose: () => void;
  active: boolean;
}

const ModalQrCode = (props: IProps) => {
  const { active, onClose } = props;
  const { wizard } = useAppSelector(wizardStateSelector);
  const { theme } = useTheme()

  const { css } = useStyle(modalQrCodeRules);
  const { config } = useAppSelector(configState);
  const { portBotUsername, portCodename } = config;
  const portUrl = `https://t.me/${portBotUsername}/port?startapp=${portCodename}`;
  const { networkStatus, qrCodeUrl } = useAppSelector(qrState)
  const dispatch = useAppDispatch()

  const [actionQr, setActionQr] = useState<'share' | 'copy' | undefined>()
  const [toast, showToast] = useCustomToast();

  const canvasRef = useRef(null);

  const { t } = useTranslation()

  const uploadQRCode  = () => {
    const canvas = canvasRef.current?.querySelector('canvas');
    if (!canvas) return;
    canvas.toBlob(blob => {
      if (blob) {
        const file = new File([blob], 'qrcode.png', { type: 'image/png' });
        dispatch(uploadQr({file: file}));
      }
    }, 'image/png');
  };

  const copyFunction = useCallback((qrCodeUrl: string) => {    
    if (document.hasFocus()) {
      showToast(t('modalQrCode.qrCodeCopied'));
      navigator.clipboard.writeText(`${qrCodeUrl}`);
    }
  }, [showToast, t])

  const shareFunction = useCallback((qrCodeUrl: string) => {
    if (!qrCodeUrl) return
    if (navigator.share) {
      navigator.share({ url: qrCodeUrl });
    } else {
      copyFunction(qrCodeUrl)
    }
  }, [copyFunction])

  const copyQRCode = () => {
    if (qrCodeUrl) {
      copyFunction(qrCodeUrl)
      return
    }
    setActionQr('copy')
    uploadQRCode()
  }

  const shareQrCode = () => {
    if (qrCodeUrl) {
      shareFunction(qrCodeUrl)
      return
    }
    setActionQr('share')
    uploadQRCode()
  }

  useEffect(() => {
    if (networkStatus !== NetworkStatus.Done || !qrCodeUrl) return
    if (actionQr === 'share') {
      shareFunction(qrCodeUrl)
    }
    if (actionQr === 'copy') copyFunction(qrCodeUrl)
  }, [networkStatus, qrCodeUrl, actionQr, copyFunction, shareFunction])

  return (
    <Modal propsStyles={{backgroundColor: theme.background}} active={active} name={ModalNames.Success} onClose={onClose}>
      <div   className={css.content}>
        <div ref={canvasRef} className={css.canvasWrapper}>
          <div className={css.shopLogoWrp}>
            <img className={css.shopLogo} src={wizard.shopLogoUrl} alt="logo"/>
          </div>
          <QRCodeCanvas  
            size={800}
            level={'H'}  
            value={portUrl}
            imageSettings={{
              src: '/images/tgsvg.svg',
              height: 200,
              width: 200,
              excavate: true,
            
            }}/>
          <p className={css.shopName}>{wizard.shopName}</p>
          <p className={css.shopDescription}>{wizard.shopDescription}</p>
        </div>
      </div>
      <ToastComponent positionX={'right'} positionY={'top'} text={toast}>
        <footer className={css.footer}>
          <Button
            disabled={networkStatus === NetworkStatus.Loading}
            onClick={copyQRCode}
            text={t('modalQrCode.copy')}
            propsStyles={{
              width: '100%',
            }}
          />
          <Button
            disabled={networkStatus === NetworkStatus.Loading}
            icon="share"
            extend={css.shareButton}
            propsStyles={{
              width: 62,
              height: 62,
              padding: '0px',
              background: theme.colorGrey,
            }}
            onClick={shareQrCode}
            hasGradient={false}
          />
        </footer>
      </ToastComponent>

    </Modal>
  );
};

export default memo(ModalQrCode);

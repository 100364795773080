import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { NetworkStatus } from '../../utils/network/network.constant';
import { CategoryTranslator } from '../translators/categoryTranslator';
import { createAppAsyncThunk } from '../../hooks/redux';
import { Category, Filter } from '@teleport/schemas-protobuf';
import { ProductsTranslator } from '../translators/productsTranslator';
import { IProduct } from 'src/types/product';

export interface IProductsCard {
  id: string | number;
  name: string;
  img: string;
  sold: number;
  modificators: number;
  category: string;
  subcategory: string;
  hidden: boolean;
  price: number;
}

export interface ICategory {
  uuid: string;
  title: string;
  subcategories?: ICategory[];
}
export type ICategoryMain = ICategory & {
  subCategories?: ICategory[];
};
interface ICategorySlice {
  categories: ICategoryMain[];
  products: IProduct[];
  networkStatus: NetworkStatus;
  productBindToCategoryNetworkStatus: NetworkStatus;
}

// products services courses events

export const getRootCategoryList = createAppAsyncThunk(
  'category/getRootCategoryList',
  async (_, thunkAPI) => {
    const result = await thunkAPI.extra.portOwnerApi().rootCategoryList({});
    return CategoryTranslator.fromRootCategoryListResponse(result);
  },
);

export const createRootCategory = createAppAsyncThunk(
  'category/createRootCategory',
  async (data: { title: string; subCategoryEnabled: boolean }, thunkAPI) => {
    const { categoryUuid } = await thunkAPI.extra.portOwnerApi().categoryUpsert({
      category: {
        type: {
          case: 'rootCategory',
          value: {
            uuid: undefined,
            title: data.title,
            subCategoryEnabled: data.subCategoryEnabled,
          },
        },
      },
    });

    return {
      uuid: categoryUuid,
      title: data.title,
      subCategoryEnabled: data.subCategoryEnabled,
    };
  },
);

export const editRootCategory = createAppAsyncThunk(
  'category/editRootCategory',
  async (data: { title: string; uuid: string }, thunkAPI) => {
    const { categoryUuid } = await thunkAPI.extra.portOwnerApi().categoryUpsert({
      category: {
        type: {
          case: 'rootCategory',
          value: {
            uuid: data.uuid,
            title: data.title,
          },
        },
      },
    });

    return {
      id: categoryUuid,
      title: data.title,
    };
  },
);

export const deleteRootCategory = createAppAsyncThunk(
  'category/deleteRootCategory',
  async (categoryUuid: string, thunkAPI) => {
    await thunkAPI.extra.portOwnerApi().rootCategoryDelete({
      rootCategoryUuid: categoryUuid,
    });

    return {
      id: categoryUuid,
    };
  },
);

export const productToCategoryBind = createAppAsyncThunk(
  'category/productToSelection',
  async ({ category, productUuids }: { category: ICategory; productUuids: string[] }, thunkAPI) => {
    const requestData = CategoryTranslator.toProductToCategoryRequest({
      category,
      productUuids,
    });
    const response = await thunkAPI.extra.portOwnerApi().productToCategoryBind(requestData)
    return {
      response: response,
      category: category,
      productUuids: productUuids
    };
  },
);

export const getProductsByExactCategory = createAppAsyncThunk(
  'products/getProductsByExactCategory',
  async (category: Category, thunkAPI) => {
    const filter = new Filter({
      filterDefaultState: { case: 'categoryFilter', value: { category: category } },
    });
    const filters = [filter];
    const result = await thunkAPI.extra.portOwnerApi().ownerCatalogProductList({ filters });

    return ProductsTranslator.fromProductListResponse(result);
  },
);

export const reorderCategories = createAppAsyncThunk(
  'uiBlocks/reorderCategories',
  async (data: { rootCategoryUuids: string[] }, thunkAPI) => {
    const result = await thunkAPI.extra.portOwnerApi().rootCategoryReorder({
      rootCategoryUuids: data.rootCategoryUuids
    })
    return result
  },
);

const initialState: ICategorySlice = {
  categories: [],
  networkStatus: NetworkStatus.None,
  productBindToCategoryNetworkStatus: NetworkStatus.None,
  products: [],
};

export const categorySlice = createSlice({
  name: 'Category',
  initialState,
  reducers: {
    setCategories: (state, { payload }: PayloadAction<ICategoryMain[]>) => {
      state.categories = payload;
    },
    addCategory: (state, action: PayloadAction<ICategory>) => {
      state.categories = [...state.categories, action.payload];
    },
    setProductsForCategory: (state, action: PayloadAction<IProduct[]>) => {
      state.products = action.payload
    },
    // addProductsToProducts: (
    //   state,
    //   action: PayloadAction<{ categoryId: number | string; productsId: number | string }>,
    // ) => {
    //   const { categoryId, productsId } = action.payload;
    //   const itemToUpdate = state.categories.find(item => item.id === categoryId);
    //   if (itemToUpdate)
    //     itemToUpdate.products = itemToUpdate?.products
    //       ? [
    //         ...itemToUpdate?.products,
    //         state.availableProductsList.find(el => `${el.id}` === `${productsId}`),
    //       ]
    //       : [state.availableProductsList.find(el => `${el.id}` === `${productsId}`)];
    // },
    // deleteProductsFromCategory: (
    //   state,
    //   action: PayloadAction<{ categoryId: number | string; productsId: number | string }>,
    // ) => {
    //   const { categoryId, productsId } = action.payload;
    //   const itemToUpdate = state.categories.find(item => item.id === categoryId);
    //   if (itemToUpdate)
    //     itemToUpdate.products = itemToUpdate.products.filter(el => el.id !== productsId);
    // },
    addSubcategoryToCategory: (
      state,
      action: PayloadAction<{ categoryId: number | string; subcategory: ICategory }>,
    ) => {
      const { categoryId, subcategory } = action.payload;
      const itemToUpdate = state.categories.find(item => item.uuid === categoryId);
      if (itemToUpdate)
        itemToUpdate.subcategories =
          itemToUpdate.subcategories?.length > 0
            ? [...itemToUpdate.subcategories, subcategory]
            : [subcategory];
    },
  },
  extraReducers: builder => {
    //getRootCategoryList
    builder.addCase(getRootCategoryList.pending, state => {
      state.networkStatus = NetworkStatus.Loading;
    });
    builder.addCase(getRootCategoryList.fulfilled, (state, action) => {
      state.categories = action.payload;

      state.networkStatus = NetworkStatus.Done;
    });
    builder.addCase(getRootCategoryList.rejected, state => {
      state.networkStatus = NetworkStatus.Failed;
    });
    //createRootCategory
    builder.addCase(createRootCategory.pending, state => {
      state.networkStatus = NetworkStatus.Loading;
    });
    builder.addCase(createRootCategory.fulfilled, (state, action) => {
      state.categories.unshift(action.payload);

      state.networkStatus = NetworkStatus.Done;
    });
    builder.addCase(createRootCategory.rejected, state => {
      state.networkStatus = NetworkStatus.Failed;
    });
    //deleteRootCategory
    builder.addCase(deleteRootCategory.pending, state => {
      state.networkStatus = NetworkStatus.Loading;
    });
    builder.addCase(deleteRootCategory.fulfilled, (state, action) => {
      state.categories = state.categories.filter(
        category => `${category.uuid}` !== `${action.payload.id}`,
      );
      state.networkStatus = NetworkStatus.Done;
    });
    builder.addCase(deleteRootCategory.rejected, state => {
      state.networkStatus = NetworkStatus.Failed;
    });
    //editRootCategory
    builder.addCase(editRootCategory.pending, state => {
      state.networkStatus = NetworkStatus.Loading;
    });
    builder.addCase(editRootCategory.fulfilled, (state, action) => {
      const category = state.categories.find(el => `${el.uuid}` === `${action.payload.id}`);
      if (category) category.title = action.payload.title;
      state.networkStatus = NetworkStatus.Done;
    });
    builder.addCase(editRootCategory.rejected, state => {
      state.networkStatus = NetworkStatus.Failed;
    });
    //getProductsByExactCategory
    builder.addCase(getProductsByExactCategory.pending, state => {
      state.networkStatus = NetworkStatus.Loading;
    });
    builder.addCase(getProductsByExactCategory.fulfilled, (state, action) => {
      state.products = action.payload;
      state.networkStatus = NetworkStatus.Done;
    });
    builder.addCase(getProductsByExactCategory.rejected, state => {
      state.networkStatus = NetworkStatus.Failed;
    });
  },
});

export const { addCategory, addSubcategoryToCategory, setCategories, setProductsForCategory } = categorySlice.actions;
export const categoryState = (state: RootState) => state[categorySlice.name];

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

interface IModalErrorSlice {
  modalErrorIsOpen: boolean;
  errorTitle:  string;
  errorText: string;
}

const initialState: IModalErrorSlice = {
  modalErrorIsOpen: false,
  errorTitle: '',
  errorText: '',
};

export const modalErrorSlice = createSlice({
  name: 'ModalError',
  initialState,
  reducers: {
    openErrorModal: (state, action: PayloadAction<{
      errorTitle: string,
      errorText: string,
    }>) => {
      state.modalErrorIsOpen = true;
      state.errorTitle = action.payload.errorTitle;
      state.errorText = action.payload.errorText
    },
    closeErrorModal: state => {
      state.modalErrorIsOpen = false;
      state.errorTitle = '';
      state.errorText = '';
    },
  },
});

export const { openErrorModal, closeErrorModal } = modalErrorSlice.actions;

export const modalErrorState = (state: RootState) => state[modalErrorSlice.name];

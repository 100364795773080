import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { NetworkStatus } from 'src/utils/network/network.constant';
import { getSelections } from '../api/selections/getSelections';
import { ISelectionWithProducts } from 'src/types/selection';
import { createSelection } from '../api/selections/createSelection';
import { editSelection } from '../api/selections/editSelection';
import { selectionDelete } from '../api/selections/selectionDelete';
import { upsertProduct } from '../api/products/upsertProduct';
import { IProduct } from 'src/types/product';
import { createAppAsyncThunk } from 'src/hooks/redux';

interface IStoriesSlice {
  selections: ISelectionWithProducts[];
  newSelection: ISelectionWithProducts;
  openThisSelection: string;
  networkStatus: NetworkStatus;
  upsertNetworkStatus: NetworkStatus;
  deleteNetworkStatus: NetworkStatus;
  saveNetworkStatus: NetworkStatus;
}

const initialState: IStoriesSlice = {
  selections: [],
  openThisSelection: '',
  networkStatus: NetworkStatus.None,
  saveNetworkStatus: NetworkStatus.None,
  newSelection: {
    uuid: 'newselection',
    title: '',
    visibility: {
      showOnMain: false,
      showInRecommendations: false
    },
    products: []
  },
  upsertNetworkStatus: NetworkStatus.None,
  deleteNetworkStatus: NetworkStatus.None,
};

export const selectionProductReorder = createAppAsyncThunk(
  'selections/selectionProductReorder',
  async (data: {selectionUuid: string, productUuids: string[];}, thunkAPI) => {
    return thunkAPI.extra.portOwnerApi().selectionProductReorder(data);
  },
);


export const selectionSlice = createSlice({
  name: 'Selections',
  initialState,
  reducers: {
    addProductsToSelection: (
      state,
      action: PayloadAction<{ selectionUuid: string; products: IProduct[] }>,
    ) => {
      const { selectionUuid, products } = action.payload;
      const selection = state.selections.find(el => el.uuid === selectionUuid);
      selection.products = products;
    },
    setOpenThisSelection: (state, action: PayloadAction<string>) => {
      state.openThisSelection = action.payload;
    },
    updateSelectionProduct: (state, action: PayloadAction<{selectionUuid: string, product: IProduct}>) => {
      const { selectionUuid, product } = action.payload;
      const selection = state.selections.find(el => el.uuid === selectionUuid);
      if (selection) {
        const productIndex = selection.products.findIndex(p => p.id === product.id);
        if (productIndex === -1) {
          selection.products.push(product);
        } else {
          selection.products.splice(productIndex, 1);
        }
      }
    },
    updateSelectionProducts: (state, action: PayloadAction<{selectionUuid: string, products: IProduct[]}>) => {
      const { selectionUuid, products } = action.payload;
      const selection = state.selections.find(el => el.uuid === selectionUuid);
      if (selection) {
        selection.products = products
      }
    },
    updateNewSelection: (state, action: PayloadAction<ISelectionWithProducts>) => {
      state.newSelection = action.payload
    },
    updateNewSelectionProducts: (state, action: PayloadAction<IProduct[]>) => {
      state.newSelection.products = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(getSelections.pending, state => {
      state.networkStatus = NetworkStatus.Loading;
    });
    builder.addCase(getSelections.fulfilled, (state, action) => {
      state.selections = action.payload;
      state.networkStatus = NetworkStatus.Done;
    });
    builder.addCase(getSelections.rejected, state => {
      state.networkStatus = NetworkStatus.Failed;
    });
    
    builder.addCase(createSelection.pending, state => {
      state.upsertNetworkStatus = NetworkStatus.Loading;
    });
    builder.addCase(createSelection.fulfilled, (state, action) => {
      state.selections.push(action.payload);
      state.upsertNetworkStatus = NetworkStatus.Done;
    });
    builder.addCase(createSelection.rejected, state => {
      state.upsertNetworkStatus = NetworkStatus.Failed;
    });

    builder.addCase(editSelection.rejected, state => {
      state.upsertNetworkStatus = NetworkStatus.Failed;
      state.saveNetworkStatus = NetworkStatus.Failed;
    });
    builder.addCase(editSelection.pending, state => {
      state.upsertNetworkStatus = NetworkStatus.Loading;
      state.saveNetworkStatus = NetworkStatus.Loading;
    });
    builder.addCase(editSelection.fulfilled, (state, action) => {
      state.saveNetworkStatus = NetworkStatus.Done;
      const editedSelection = action.payload;
      const selection = state.selections.find(el => el.uuid === editedSelection.uuid);
      const selectionIndex = state.selections.indexOf(selection);
      if (selectionIndex === -1) return;
      state.selections.splice(selectionIndex, 1, editedSelection);
      state.upsertNetworkStatus = NetworkStatus.Done;
    });
    
    builder.addCase(upsertProduct.fulfilled, state => {
      state.networkStatus = NetworkStatus.None;
    });

    builder.addCase(selectionDelete.rejected, state => {
      state.deleteNetworkStatus = NetworkStatus.Failed;
      state.saveNetworkStatus = NetworkStatus.Failed;
    });
    builder.addCase(selectionDelete.pending, state => {
      state.deleteNetworkStatus = NetworkStatus.Loading;
      state.saveNetworkStatus = NetworkStatus.Loading;
    });
    builder.addCase(selectionDelete.fulfilled, (state, action) => {
      state.saveNetworkStatus = NetworkStatus.Done;
      state.selections = state.selections.filter(el => el.uuid !== action.payload);
      state.deleteNetworkStatus = NetworkStatus.Done;
    });
  },
});

export const { addProductsToSelection, setOpenThisSelection, updateSelectionProduct, updateNewSelection, updateSelectionProducts, updateNewSelectionProducts } = selectionSlice.actions;

export const selectionsState = (state: RootState) => state[selectionSlice.name];

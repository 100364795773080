import { padding } from 'src/utils/applyCSSProperty ';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface ICreateModifierPageKeys {
  header: {};
}

export type ICreateModifierPage = IRuleFn<ICreateModifierPageKeys>;

const CreateModifierPageRuleFn: ICreateModifierPage = props => {
  const {} = props;

  return {
    header: {
      ...padding(16),
      display: 'flex',
      justifyContent: 'space-between',
      gap: 10,
      alignItems: 'center',
    },
  };
};

export const CreateModifierPageRules = createCachedStyle(CreateModifierPageRuleFn);

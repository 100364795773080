import { IRuleFn } from '../../utils/theme/fela.interface';
import { padding } from '../../utils/applyCSSProperty ';
import { createCachedStyle } from '../../utils/theme/createCachedStyle';

export interface IPromoCodesPageKeys {
  header: {};
  wrapper: {};
  promoCodesWrapper: {};
  spinnerWrapper: {};
}

export type IPromoCodesPage = IRuleFn<IPromoCodesPageKeys>;

const PromoCodesPageRuleFn: IPromoCodesPage = props => {
  const { sidePadding } = props.theme;

  return {
    header: { ...padding(16), display: 'flex', columnGap: 8 },
    container: {
      overflow: 'hidden',
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 8,
      paddingBottom: 8,
      paddingRight: 16,
    },
    promoCodesWrapper: {
      minHeight: '100%',
      display: 'flex',
      flexDirection: 'column',
      rowGap: 8,
      ...padding(4, sidePadding, 8),
    },
    spinnerWrapper: {
      flexGrow: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  };
};

export const PromoCodesPageRules = createCachedStyle(PromoCodesPageRuleFn);

import { FC, memo, useEffect } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { ModifiersPageRules } from './ModifiersPage.style';
import { Layout } from 'src/components/Layout/Layout';
import Footer from 'src/components/Footer/Footer';
import { Button } from 'src/components/UI/Button/Button';
import Text from 'src/components/UI/Text/Text';
import Container from 'src/components/Container/Container';
import useBackButton from 'src/hooks/useBackButton';
import { useNavigate } from 'react-router-dom';
import { useScrollToTop } from 'src/hooks/useScrollToTop';
import useLoader from 'src/hooks/useLoader';
import { NoSettings } from 'src/components/NoSettings/NoSettings';
import { RoutePath } from '../../routing/routeConfig';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import { getModifiersList, modifiersSelector } from 'src/redux/slices/modifiersSlice';
import { ModifierCard } from 'src/components/ModifierCard/ModifierCard';
import { useTranslation } from 'src/utils/i18n/hooks/useTranslation';
import { NetworkStatus } from 'src/utils/network/network.constant';

//Page to display all Modifiers
export const ModifiersPage: FC = memo(function ModifiersPage(props) {
  const {} = props;
  const { css } = useStyle(ModifiersPageRules);
  const BackButton = useBackButton();
  const navigate = useNavigate();
  const { hideLoader } = useLoader();
  const dispatch = useAppDispatch();
  const { modifiers, networkStatus } = useAppSelector(modifiersSelector);

  useEffect(() => {
    if (networkStatus === NetworkStatus.None) {
      dispatch(getModifiersList());
    }
    if (networkStatus !== NetworkStatus.None && networkStatus !== NetworkStatus.Loading) {
      hideLoader();
    }
  }, [
    dispatch,
    hideLoader,
    networkStatus
  ]);

  useScrollToTop();
  useEffect(() => {
    BackButton.onClickCustom(goBack);
    function goBack() {
      navigate('/');
    }
    return () => {
      BackButton?.offClickCustom(goBack);
    };
  }, [BackButton, navigate]);

  const { t } = useTranslation()

  return (
    <Layout
      header={
        <header className={`${css.header} header`}>
          <Text
            text={t('modifiersPage.modifiers')}
            mod="title"
            fontWeight={800}
            fontSize={20}
            lineHeight={22}
          />
        </header>
      }
      footer={
        <Footer>
          <Button
            href={RoutePath.CreateModifier}
            text={t('modifiersPage.createModifier')}
            propsStyles={{ width: '100%' }}
          />
        </Footer>
      }
    >
      <Container extend={css.container}>
        {modifiers.length > 0 ? (
          <div className={css.grid}>
            {modifiers.map(el => {
              return (
                <ModifierCard
                  key={el.uuid}
                  modifierData={el}
                />
              );
            })}
          </div>
        ) : (
          <NoSettings
            text={t('modifiersPage.createModifiersSoUsersCanSelectProductsBySizeColorAndOtherAttributes')}
            title={t('modifiersPage.youHaventCreatedAnyModifiersYet')}
            icon="data-large"
          />
        )}
      </Container>
    </Layout>
  );
});

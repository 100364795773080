import { padding } from 'src/utils/applyCSSProperty ';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface IModalModifierVariantsKeys {
  modalInner: {};
  header: {};
  inputWrp: {};
  add_modifier: {};
  footer: {};
}

interface IModalModifierVariantsProps {
  headerHeight: number;
  footerHeight: number;
}

export type IModalModifierVariants = IRuleFn<
  IModalModifierVariantsKeys,
  IModalModifierVariantsProps
>;

const ModalModifierVariantsRuleFn: IModalModifierVariants = props => {
  const { sidePadding, mainColor, colorWhite } = props.theme;
  const { headerHeight, footerHeight } = props;

  return {
    modalInner: {
      position: 'relative',
      paddingLeft: sidePadding,
      paddingRight: sidePadding,
      maxHeight: `calc(100% - ${headerHeight}px)`,
      overflow: 'auto',
      display: 'flex',
      flexDirection: 'column',
      gap: 24,
      paddingTop: 19,
      paddingBottom: `${footerHeight}px`,
    },
    header: {
      ...padding(10, sidePadding, 12),
    },
    inputWrp: {
      ...padding(12, 0),
    },
    add_modifier: {
      fontSize: 12,
      lineHeight: '12px',
      fontWeight: 700,
      textAlign: 'center',
      color: mainColor,
    },
    footer: {
      width: '100%',
      position: 'fixed',
      backgroundColor: colorWhite,
      bottom: 0,
      paddingTop: 12,
      ...padding(12, sidePadding, 30),
    },
  };
};

export const modalModifierTypeRules = createCachedStyle(ModalModifierVariantsRuleFn);

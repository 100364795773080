import { IRuleFn } from '../../utils/theme/fela.interface';
import { createCachedStyle } from '../../utils/theme/createCachedStyle';

export interface IPromoCodeCardKeys {
  card: {},
  title: {},
  top: {},
  noActive: {}
}

export type IPromoCodeCard = IRuleFn<IPromoCodeCardKeys>;

const PromoCodeCardRuleFn: IPromoCodeCard = props => {
  const { colorWhite, colorRed } = props.theme;

  return {
    card: {
      padding: 12,
      backgroundColor: colorWhite,
      borderRadius: 12,
    },
    title: {
      marginBottom: 4
    },
    top: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    noActive: {
      color: colorRed,
    }
  };
};

export const PromoCodeCardRules = createCachedStyle(PromoCodeCardRuleFn);

import { FC, ReactNode, memo, useState } from 'react';

interface IProps {
  src: string;
  alt: string;
  fallback: ReactNode;
  className?: string;
}

export const ImageComponent: FC<IProps> = memo(function ImageComponent(props) {
  const { alt, fallback, src, className } = props;
  const [error, setError] = useState(false);

  const onError = () => {
    setError(true);
  };

  return error ? fallback : <img loading='lazy' src={src} alt={alt} className={className} onError={onError} />;
});

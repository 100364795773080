import { FC, memo } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { storeCardRules } from './StoreCard.style';
import { ImageComponent } from '../ImageComponent/ImageComponent';
import { Icon } from '../UI/Icon/Icon';
import { StoreContext } from '@teleport/schemas-protobuf/hub/v1/hub_wizard_models_pb';
import { UserRole } from '@teleport/schemas-protobuf';
import { useTranslation } from '../../utils/i18n/hooks/useTranslation';

interface IProps {
  storeContext: StoreContext,
  onClick: (storeUuid: string) => void,
  disabled?: boolean,
}

export const StoreCard: FC<IProps> = memo(function Step(props) {
  const { storeContext, disabled, onClick } = props;
  const { store, active, userRole  } = storeContext;
  const { css } = useStyle(storeCardRules, {active, disabled});
  const { t } = useTranslation();

  const getUserRole = (role: UserRole) => {
    switch (role) {
      case UserRole.OWNER:
        return t('mainPageHeader.owner');
      case UserRole.ADMIN:
        return t('mainPageHeader.administrator');
      default:
        return ''
    }
  }

  const onClickCard = () => {
    onClick(store.uuid)
  }

  return (
    <div className={css.card} onClick={onClickCard}>
      <div className={css.imgWrapper}>
        <ImageComponent
          className={css.image}
          alt="slide-img"
          src={store.naming.logo}
          fallback={<Icon name="no-image" properties={{ className: css.image}} />}
        />
      </div>
      <div className={css.textInfo}>
        <p className={css.name}>{store.naming.name}</p>
        <p className={css.description}>{store.naming.description}</p>
      </div>
      <p className={css.role}>{getUserRole(userRole)}</p>
    </div>
  );
});

import { FC, memo, useCallback, useEffect, useState } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { Layout } from 'src/components/Layout/Layout';
import Text from 'src/components/UI/Text/Text';
import Container from 'src/components/Container/Container';
import { CreateSelectionPageRules } from './CreateSelectionPage.style';
import useLoader from 'src/hooks/useLoader';
import useBackButton from 'src/hooks/useBackButton';
import { useNavigate } from 'react-router-dom';
import { useScrollViewport } from 'src/hooks/useScrollViewport';
import { useTranslation } from 'src/utils/i18n/hooks/useTranslation';
import { SelectionForm } from 'src/components/SelectionForm/SelectionForm';

export const CreateSelectionPage: FC = memo(function CreateSelectionPage(props) {
  const {} = props;
  const { css } = useStyle(CreateSelectionPageRules);
  const BackButton = useBackButton();
  const { hideLoader } = useLoader();
  const navigate = useNavigate();


  useEffect(() => {
    hideLoader();
  }, [hideLoader]);

  const [layoutEl, setLayoutEl] = useState({ current: null });

  const layoutRef = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      setLayoutEl({ current: node });
    }
  }, []);

  useScrollViewport({scrollElementRef: layoutEl});

  useEffect(() => {
    BackButton.onClickCustom(goBack);
    function goBack() {
      navigate(-1);
    }
    return () => {
      BackButton?.offClickCustom(goBack);
    };
  }, [BackButton, navigate]);

  const { t } = useTranslation()

  return (
    <>
      <Layout
        header={
          <header className={`${css.header} header`}>
            <Text text={t('createSelectionPage.selection')} mod="title" fontWeight={800} fontSize={20} lineHeight={22} />
          </header>
        }
        ref={layoutRef}
      >
        <Container extend={css.container}>
          <SelectionForm />
        </Container>
      </Layout>
    </>
  );
});

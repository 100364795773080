import { padding } from 'src/utils/applyCSSProperty ';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface IModalWarningAsyncChangesKeys {
  modalInner: {};
  footer: {};
  buttonNoShowMore: {};
}

export interface IModalWarningAsyncChangesProps {
  footerHeight: number;
}

export type IModalWarningAsyncChanges = IRuleFn<
  IModalWarningAsyncChangesKeys,
  IModalWarningAsyncChangesProps
>;

const ModalWarningAsyncChangesRuleFn: IModalWarningAsyncChanges = props => {
  const { sidePadding, colorWhite } = props.theme;
  const { footerHeight } = props;

  return {
    modalInner: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      position: 'relative',
      maxHeight: '100%',
      overflow: 'auto',
      paddingTop: 40,
      paddingLeft: sidePadding,
      paddingRight: sidePadding,
      paddingBottom: footerHeight,
      textAlign: 'center',
    },
    footer: {
      ...padding(12,sidePadding, 30),
      width: '100%',
      backgroundColor: colorWhite,
      position: 'fixed',
      left: 0,
      bottom: 0,
      display: 'flex',
      columnGap: '12px',
    },
    buttonNoShowMore: {
      minHeight: 62,
      marginTop: 64,
      marginBottom: 8,
    },
  };
};

export const ModalWarningAsyncChangesRules = createCachedStyle(ModalWarningAsyncChangesRuleFn);

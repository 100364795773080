import { IRuleFn } from '../../utils/theme/fela.interface';
import { createCachedStyle } from '../../utils/theme/createCachedStyle';

export interface ICreatePromoCodePageKeys {
  container: {};
}

export type ICreatePromoCodePage = IRuleFn<ICreatePromoCodePageKeys>;

const CreatePromoCodePageRuleFn: ICreatePromoCodePage = props => {
  const {} = props.theme;
  const {} = props;

  return {
    container: {
      overflow: 'hidden',
    },
  };
};

export const CreatePromoCodePageRules = createCachedStyle(CreatePromoCodePageRuleFn);

import { useState, useEffect, useCallback } from 'react';

const useCustomToast = () => {
  const [toast, setToast] = useState(null);

  const showToast = useCallback(message => {
    setToast(message);
  }, []);

  useEffect(() => {
    if (!toast) return () => false
    const timer = setTimeout(() => {
      setToast(null);
    }, 3000);
    return () => clearTimeout(timer);
  }, [toast]);

  return [toast, showToast];
};

export default useCustomToast;

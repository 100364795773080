import { ModifierType } from '@teleport/schemas-protobuf';
import { useTranslation } from '../utils/i18n/hooks/useTranslation';
import { useMemo } from 'react';

interface IModifierType {
  id: number,
  title: string,
  text: string,
  infoImg: string,
  infoText: string,
  type: ModifierType
}

export const useModifierTypeList = (): IModifierType[] => {
  const {t} = useTranslation();

  return useMemo(() => [
    {
      id: 1,
      title: t('modifierType.toggle'),
      text: t('modifierType.optionActivationEgChoosingAnAdditionalFeature'),
      infoImg: '/images/modifires-info/1.png',
      infoText: t('modifierType.canBeToggledOnOrOff'),
      type: ModifierType.TOGGLE,
    },
    {
      id: 2,
      title: t('modifierType.options'),
      text: t('modifierType.choosingAnOptionFromARangeEgSizeSelection'),
      infoImg: '/images/modifires-info/2.png',
      infoText: t('modifierType.oneOptionCanBeSelectedFromTheList'),
      type: ModifierType.CHIPS
    },
    {
      id: 3,
      title: t('modifierType.list'),
      text: t('modifierType.choosingAnOptionFromADropdownListEgPackageSelection'),
      infoImg: '/images/modifires-info/3.png',
      infoText: t('modifierType.oneOptionCanBeSelected'),
      type: ModifierType.LIST,
    },
  ], [t]);
}

import { createRenderer } from 'fela';
import unit from 'fela-plugin-unit';
import typescript from 'fela-plugin-typescript';
import expandShorthand from 'fela-plugin-expand-shorthand';
import monolithic from 'fela-monolithic';
import { fonts } from './fonts';

export const renderer = createRenderer({
  plugins: [typescript(), unit('px'), expandShorthand(true)],
  enhancers: [
    monolithic({
      prettySelectors: true,
    }),
  ],
});

fonts.forEach(font => renderer.renderFont(font.name, font.files, font.options));

renderer.renderStatic(
  {
    margin: 0,
    padding: 0,
    boxSizing: 'border-box',
    '-webkit-tap-highlight-color': 'transparent',
  },
  '*',
);

renderer.renderStatic(
  {
    fontFamily: 'Onest, sans-serif',
    letterSpacing: '-0.4px',
    color: '#1c1916',
  },
  'body',
);

renderer.renderStatic(
  {
    minHeight: '100vh',
    height: '100vh',
    overflow: 'hidden',
  },
  'body',
);

renderer.renderStatic(
  {
    height: 'auto',
    overflow: 'hidden',
  },
  'html',
);

renderer.renderStatic(
  {
    textDecoration: 'none',
    color: 'inherit',
  },
  'a',
);

// renderer.renderStatic({ height: "100%" }, "html");
// renderer.renderStatic({ minHeight: "100vh" }, "body");

renderer.renderStatic({ fontFamily: 'inherit' }, 'button, input, textarea');

import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface IImageLoaderKeys {
  imageLoader: {};
  labelText: {};
  image: {};
  message: {};
}

interface IImageLoaderProps {
  width: number;
  height: number;
  borderRadius: string;
  error: boolean;
}

export type IImageLoader = IRuleFn<IImageLoaderKeys, IImageLoaderProps>;

const ImageLoaderRuleFn: IImageLoader = props => {
  const { mainColor, colorGrey, colorRed, colorRgbBlack } = props.theme;
  const { width, height, error, borderRadius } = props;

  return {
    imageLoader: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',

      '& input': {
        position: 'absolute',
        zIndex: -1,
        opacity: 0,
      },
    },
    image: {
      width: width,
      height: height,
      borderRadius: borderRadius,
      backgroundColor: colorGrey,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
    },
    labelText: {
      marginTop: 8,
      userSelect: 'none',
      color: mainColor,
    },
    message: {
      marginTop: 2,
      color: error ? colorRed : `rgba(${colorRgbBlack}, .5)`,
    },
  };
};

export const ImageLoaderRules = createCachedStyle(ImageLoaderRuleFn);

import { FC, memo, useCallback, useEffect } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { Layout } from 'src/components/Layout/Layout';
import Text from 'src/components/UI/Text/Text';
import Container from 'src/components/Container/Container';
import useBackButton from 'src/hooks/useBackButton';
import { useNavigate, useParams } from 'react-router-dom';
import { useScrollToTop } from 'src/hooks/useScrollToTop';
import useLoader from 'src/hooks/useLoader';
import { EdiModifierPageRules } from './EditModifierPage.style';
import { CreateUpdateModifier } from 'src/components/CreateUpdateModifier/CreateUpdateModifier';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import { getModifiersList, IModifierOmit, modifiersSelector, resetNetworkStatus } from 'src/redux/slices/modifiersSlice';
import { NetworkStatus } from 'src/utils/network/network.constant';
import { RoutePath } from '../../routing/routeConfig';
import { useTranslation } from 'src/utils/i18n/hooks/useTranslation';


//Page to edit create Modifier
export const EdiModifierPage: FC = memo(function EdiModifierPage(props) {
  const {} = props;
  const { css } = useStyle(EdiModifierPageRules);
  const BackButton = useBackButton();
  const navigate = useNavigate();
  const { hideLoader } = useLoader();
  const dispatch = useAppDispatch()
  const { id } = useParams();

  const { modifiers, networkStatus, networkDeleteStatus } = useAppSelector(modifiersSelector);

  const { t } = useTranslation()

  useEffect(() => {
    if (networkDeleteStatus === NetworkStatus.Done) {
      dispatch(resetNetworkStatus('networkDeleteStatus'))
      navigate(RoutePath.Modifiers);
    }
  }, [networkDeleteStatus, dispatch, navigate])

  useEffect(() => {
    if (networkStatus === NetworkStatus.None) {
      dispatch(getModifiersList());
    }
    if (networkStatus !== NetworkStatus.None && networkStatus !== NetworkStatus.Loading) {
      hideLoader();
    }
  }, [
    dispatch,
    hideLoader,
    networkStatus
  ]);

  useScrollToTop();
  useEffect(() => {
    BackButton.onClickCustom(goBack);
    function goBack() {
      navigate(-1);
    }
    return () => {
      BackButton?.offClickCustom(goBack);
    };
  }, [BackButton, navigate]);

  const saveModifierCallBack = useCallback(() => {
    navigate(RoutePath.Modifiers);
  }, [navigate])

  const currentModifier:IModifierOmit  = modifiers.find(modifier => `${modifier.uuid}` === `${id}` )

  return (
    <Layout
      header={
        <header className={`${css.header} header`}>
          <Text text={t('editModifierPage.modifier')} mod="title" fontWeight={800} fontSize={20} lineHeight={22} />
        </header>
      }
    >
      <Container extend={css.container}>
        {currentModifier && (
          <CreateUpdateModifier saveModifierCallBack={saveModifierCallBack} modifierData={currentModifier} />
        )}
      </Container>
    </Layout>
  );
});

import { FC, memo, useCallback, useState } from 'react';
import { useStyle } from '../../utils/theme/useStyle';
import { EditPromoCodePageRules } from '../EditPromoCodePage/EditPromoCodePage.style';
import { useScrollViewport } from '../../hooks/useScrollViewport';
import { Layout } from '../../components/Layout/Layout';
import Container from '../../components/Container/Container';
import { PromoCodeForm } from '../../components/PromoCodeForm/PromoCodeForm';
import { useParams } from 'react-router-dom';
import { useTranslation } from '../../utils/i18n/hooks/useTranslation';
import { useAppSelector } from '../../hooks/redux';
import { promoCodesSelector } from '../../redux/slices/promoCodesSlice';

export const EditPromoCodePage: FC = memo(function EditPromoCodePage() {
  const { css } = useStyle(EditPromoCodePageRules);
  const [layoutEl, setLayoutEl] = useState({ current: null });
  const { t } = useTranslation();
  const { upsertPromoCodeStatus } = useAppSelector(promoCodesSelector);
  const layoutRef = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      setLayoutEl({ current: node });
    }
  }, []);
  const { id } = useParams();

  useScrollViewport({scrollElementRef: layoutEl});

  return (
    <Layout
      ref={layoutRef}
      errorData={{
        title: t('editPromoCodesPage.failedToEditPromoCode'),
        triggers: [upsertPromoCodeStatus]
      }}
    >
      <Container extend={css.container}>
        <PromoCodeForm promoCodeUuid={id} />
      </Container>
    </Layout>
  );
});

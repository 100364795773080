import { FC, memo, useCallback, useState } from 'react';
import { useStyle } from '../../utils/theme/useStyle';
import { useScrollViewport } from '../../hooks/useScrollViewport';
import { Layout } from '../../components/Layout/Layout';
import Container from '../../components/Container/Container';
import { PromoCodeForm } from '../../components/PromoCodeForm/PromoCodeForm';
import { CreatePromoCodePageRules } from './CreatePromoCodePage.style';
import { useAppSelector } from '../../hooks/redux';
import { promoCodesSelector } from '../../redux/slices/promoCodesSlice';
import { useTranslation } from '../../utils/i18n/hooks/useTranslation';

export const CreatePromoCodePage: FC = memo(function CreatePromoCodePage() {
  const { css } = useStyle(CreatePromoCodePageRules);
  const [layoutEl, setLayoutEl] = useState({ current: null });
  const { t } = useTranslation();
  const layoutRef = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      setLayoutEl({ current: node });
    }
  }, []);
  const { upsertPromoCodeStatus } = useAppSelector(promoCodesSelector);
  useScrollViewport({scrollElementRef: layoutEl});

  return (
    <Layout
      ref={layoutRef}
      errorData={{
        title: t('createPromoCodePage.failedToCreatePromoCode'),
        triggers: [upsertPromoCodeStatus]
      }}
    >
      <Container extend={css.container}>
        <PromoCodeForm />
      </Container>
    </Layout>
  );
});

import { IRuleFn } from 'src/utils/theme/fela.interface';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { padding } from 'src/utils/applyCSSProperty ';

export interface ICategoryPageKeys {
  header: {};
  grid: {};
  container: {};
}

export type ICategoryPages = IRuleFn<ICategoryPageKeys>;

const CategoryPageRuleFn: ICategoryPages = () => {
  return {
    header: {
      ...padding(16),
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: 10,
    },
    grid: {
      display: 'flex',
      flexDirection: 'column',
      gap: 8,
      height: '100%',
    },
    container: {
      height: '100%',
    }
  };
};

export const categoryPageRules = createCachedStyle(CategoryPageRuleFn);

import { padding } from 'src/utils/applyCSSProperty ';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface ISettingProductModifierKeys {
  wrapper: {};
  modifierItem: {};
  modifierItemsWrapper: {};
  supportText: {};
  affectPrice: {};
}

export type ISettingProductModifier = IRuleFn<ISettingProductModifierKeys>;

const SettingProductModifierRuleFn: ISettingProductModifier = props => {
  const {} = props.theme;
  const {} = props;

  return {
    wrapper: {
      overflow: 'hidden',
    },
    modifierItemsWrapper: {
      display: 'flex',
      flexDirection: 'column',
      ...padding(10, 0, 12),
      gap: 24,
    },
    modifierItem: {
      display: 'grid',
      gridTemplateColumns: 'auto 1fr 100px',
      alignItems: 'center',
      columnGap: 20,
    },
    supportText: {
      marginBottom: 16,
    },
    affectPrice: {
      maxWidth: 100,
    },
  };
};

export const SettingProductModifierRules = createCachedStyle(SettingProductModifierRuleFn);

import Compressor from 'compressorjs';
import { DEV_TELEGRAM_INIT } from 'src/utils/constants';

export const filesUpload =
  (apiUrl: string) =>
    (files: File[], ttl?: string): Promise<string[]> => {
      const authData = window?.Telegram?.WebApp?.initData
        ? `tma ${window.Telegram.WebApp.initData}`
        : `tma ${DEV_TELEGRAM_INIT ?? ''}`;

      async function upload(file: File) {
        const result = await fetch(`${apiUrl}/apis/fs/v1/upload${ttl?`?ttl=${ttl}`:''}`, {
          method: 'POST',
          headers: {
            'Content-Type': file.type,
            Authorization: authData,
          },
          body: file,
        });

        if (result.status === 401) {
          const hubBotUsername = window.localStorage.getItem('hubBotUsername');
          location.href = `https://t.me/${hubBotUsername}/hub`;
          return;
        }

        const { uri } = await result.json();

        return uri;
      }

      return Promise.all(
        files.map(async file => {
          const compress = new Promise((resolve, reject) => {
          // If file type is an image try to compress it
            if (['image/png', 'image/jpeg', 'image/webp'].includes(file.type)) {
              new Compressor(file, {
                maxHeight: 700,
                maxWidth: 700,
                resize: 'cover',
                quality: 0.7,
                success(compressed) {
                  resolve(compressed);
                },
                error(error) {
                  console.warn('Could not compress image, ', error.message);
                  reject();
                },
              });
            } else {
              resolve(file);
            }
          });

          const compressedFile = (await compress) as unknown as File;

          return await upload(compressedFile ?? file);
        }),
      );
    };

import { FC, memo, useRef } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import Text from '../UI/Text/Text';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import { ISelectionWithProducts } from 'src/types/selection';
import { SubmitHandler, useForm } from 'react-hook-form';
import { selectionsState, updateNewSelection } from 'src/redux/slices/selectionsSlice';
import { useTranslation } from 'src/utils/i18n/hooks/useTranslation';
import { editSelection } from 'src/redux/api/selections/editSelection';
import { selectionDelete } from 'src/redux/api/selections/selectionDelete';
import { useTheme } from 'src/utils/theme/useTheme';
import { createSelection } from 'src/redux/api/selections/createSelection';
import { Input } from '../UI/Input/Input';
import { ListButton } from '../UI/ListButton/ListButton';
import Toggler from '../UI/Toggler/Toggler';
import { Button } from '../UI/Button/Button';
import { NetworkStatus } from 'src/utils/network/network.constant';
import { ModalSelectionFormRules } from './SelectionForm.style';
import { generatePath, useNavigate } from 'react-router-dom';
import { RoutePath } from 'src/routing/routeConfig';
import { newSelectionPageName } from 'src/utils/constants';
import { productToSelectionBind } from 'src/redux/api/selections/productToSelectionBind';

interface IProps {
  selection?: ISelectionWithProducts;
}

export const SelectionForm: FC<IProps> = memo(function SelectionForm(props) {

  const { css } = useStyle(ModalSelectionFormRules);
  const { theme } = useTheme();
  const { colorGrey, colorBlack } = theme;

  const { selection } = props;
  const dispatch = useAppDispatch();
  const { saveNetworkStatus, newSelection } = useAppSelector(selectionsState);
  const formRef = useRef(null);
  const { t, tPlural } = useTranslation();
  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      uuid: selection ? selection?.uuid : newSelection.uuid ,
      title: selection ? selection?.title : newSelection.title,
      visibility: {
        showOnMain: selection ? selection.visibility.showOnMain : newSelection.visibility.showOnMain,
        showInRecommendations: selection ? selection.visibility.showInRecommendations : newSelection.visibility.showInRecommendations,
      },
      products: selection? selection.products : newSelection.products
    },
  });
  const selectionFormState = watch()

  const onSubmit: SubmitHandler<ISelectionWithProducts> = data => {

    if (selection) {
      dispatch(editSelection({...data, products: selection?.products ?? []})).then(() => navigate(RoutePath.Selections));
    }else {
      dispatch(createSelection({...data, products: newSelection.products, uuid: ''})).then(res => {
        const result = res.payload as ISelectionWithProducts;
        dispatch(
          productToSelectionBind({
            selection: {
              ...data,
              uuid: result.uuid,
            },
            productUuids: newSelection.products.map(el => el.id),
          }),
        );
        navigate(RoutePath.Selections)
      });
    }
  };

  function onClickDeleteSelection() {
    dispatch(selectionDelete(selection)).then(() => navigate(RoutePath.Selections));
  }

  function goToselectionProducts() {
    if (!selection) dispatch(updateNewSelection(selectionFormState))
    navigate(generatePath(RoutePath.SelectionProducts, {id: selection? selection.uuid : newSelectionPageName}))
  }

  const changeShowOnMain = () => {
    const currentVisibility = watch('visibility')
    setValue('visibility', {
      ...currentVisibility,
      showOnMain: !currentVisibility.showOnMain
    })
  }
  const changeShowInRecommendations = () => {
    const currentVisibility = watch('visibility')
    setValue('visibility', {
      ...currentVisibility,
      showInRecommendations: !currentVisibility.showInRecommendations
    })
  }

  return (
    <>
      <form ref={formRef} className={css.form} onSubmit={handleSubmit(onSubmit)}>
        <div className={css.wrapper}>
          <Input
            {...register('title', {
              required: t('selectionForm.requiredField'),
            })}
            controlled={false}
            type="text"
            label={t('selectionForm.name')}
            placeholder={t('selectionForm.enterCollectionName')}
            maxLength={200}
            labelTextTransform='uppercase'
            errorMessage={errors.title?.message}
          />
        </div>
        <div className={css.wrapper}>
          <ListButton
            onClick={goToselectionProducts}
            placeholder={
              selection?.products.length > 0 || newSelection.products.length > 0
                ? tPlural('selectionForm.selectedCountProducts', selection?.products.length || newSelection.products.length)
                : t('selectionForm.selectProductsFromList')
            }
            titleFontWeight={800}
            title={t('selectionForm.products')}
            titleFontSize={12}
            titleUppercase={true}
            bg={theme.background}
          />
        </div>
        <div className={css.wrapper}>
          <div className={css.justifyWrapper}>
            <Text text={t('selectionForm.showOnHomepage')} mod="title" fontSize={12} textTransform='uppercase' />
            <Toggler
              name="showOnMain"
              value="showOnMain"
              checked={watch('visibility').showOnMain}
              onChange={changeShowOnMain}
            />
          </div>
        </div>
        <div className={css.wrapper}>
          <div className={css.justifyWrapper}>
            <Text
              text={t('selectionForm.showInRecommendations')}
              mod="title"
              textTransform='uppercase'
              fontSize={12}
            />
            <Toggler
              name="showInRecommendations"
              value="showInRecommendations"
              checked={watch('visibility').showInRecommendations}
              onChange={changeShowInRecommendations}
            />
          </div>
        </div>
        {selection && (
          <div className={css.removeBtnWrapper}>
            <Button
              text={t('selectionForm.delete')}
              propsStyles={{ background: colorGrey, color: colorBlack, width: '100%' }}
              hasGradient={false}
              onClick={onClickDeleteSelection}
              disabled={saveNetworkStatus === NetworkStatus.Loading}
            />
          </div>
        )}

        <div className={css.footer}>
          <Button
            disabled={saveNetworkStatus === NetworkStatus.Loading}
            text={
              selection
                ? t('selectionForm.save') 
                : t('selectionForm.createSelection')
            }
            propsStyles={{ width: '100%' }}
            type="submit"
          />
        </div>
      </form>
    </>
  );
});

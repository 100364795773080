import { FC, memo } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { selectionCardRules } from './SelectionCard.style';
import Text from 'src/components/UI/Text/Text';
import { Icon } from 'src/components/UI/Icon/Icon';
import { ImageComponent } from 'src/components/ImageComponent/ImageComponent';
import { ISelectionWithProducts } from 'src/types/selection';
import { v4 } from 'uuid';
import { useTranslation } from 'src/utils/i18n/hooks/useTranslation';
import { generatePath, useNavigate } from 'react-router-dom';
import { RoutePath } from 'src/routing/routeConfig';

interface IProps {
  selectionData: ISelectionWithProducts;
}

export const SelectionCard: FC<IProps> = memo(function SelectionCard(props) {
  const { selectionData } = props;
  const { title, uuid, products } = selectionData;
  const { tPlural } = useTranslation();
  const { css } = useStyle(selectionCardRules);
  const productsGrid = products.slice(0, 4);

  if (productsGrid.length < 4) {
    const missingElements = 4 - productsGrid.length;
    for (let i = 0; i < missingElements; i++) {
      productsGrid.push(null);
    }
  }
  
  const navigate = useNavigate()

  const goToEditPage = () => {
    navigate(generatePath(RoutePath.EditSelection, {id: selectionData.uuid}))
  }

  return (
    <div className={css.card} id={uuid} onClick={goToEditPage}>
      <div className={css.productsGrid}>
        {productsGrid.map(product => {
          if (!product) {
            return <div className={css.emptyBlock} key={v4()}></div>;
          }
          if (product.imageUrls.length) {
            return (
              <div key={product.id} className={css.productImg}>
                <ImageComponent
                  alt="product-img"
                  src={product.imageUrls[0]}
                  fallback={<Icon name="no-image" properties={{ className: css.fallbackSvg }} />}
                />
              </div>
            );
          } else {
            return (
              <div key={product.id} className={css.productImg}>
                <Icon name="no-image" properties={{ className: css.fallbackSvg }} />
              </div>
            );
          }
        })}
      </div>
      <div className={css.contentWrapper}>
        <div className={css.titleWrapper}>

          <Text
            mod="title"
            text={title}
            extend={css.title}
            fontSize={14}
            lineHeight={16} />
        </div>
        <Text
          mod="text"
          text={tPlural('selectionCard.countProducts', products.length)}
          fontSize={12}
          lineHeight={16}
          fontWeight={500}
        />
      </div>
    </div>
  );
});

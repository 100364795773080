import { IRuleFn } from '../../utils/theme/fela.interface';
import { createCachedStyle } from '../../utils/theme/createCachedStyle';

export interface IEditPromoCodePageKeys {
  container: {};
}

export type IEditPromoCodePage = IRuleFn<IEditPromoCodePageKeys>;

const EditPromoCodePageRuleFn: IEditPromoCodePage = () => {
  return {
    container: {
      overflow: 'hidden',
    },
  };
};

export const EditPromoCodePageRules = createCachedStyle(EditPromoCodePageRuleFn);

import { FC, memo, useMemo} from 'react';
import { Icon } from '../UI/Icon/Icon';
import { useStyle } from 'src/utils/theme/useStyle';
import { ProductsListRules } from './ProductsList.style';
import { IProduct } from 'src/types/product';
import { ProductsCardSortable } from '../ProductsCard/ProductsCardSortable';
import { closestCenter, DndContext, PointerSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';

interface IProps {
  notationText?: string;
  products: IProduct[];
  onReorderCallback: (products: IProduct[]) => void
}

export const ProductsList: FC<IProps> = memo(function ProductsList(props) {
  const { products, notationText, onReorderCallback } = props;
  const { css } = useStyle(ProductsListRules);
  const enabledThirdIndices = useMemo(() => {
    return products.map((item, index) => item.enabled ? index : -1).filter(index => index !== -1).filter((_, i) => (i + 1) % 3 === 0);
  }, [products])
  const sensors = useSensors(useSensor(TouchSensor), useSensor(PointerSensor));

  let ts: number | undefined;
  const onTouchStart = (e: TouchEvent) => {
    ts = e.touches[0].clientY;
  };
  const onTouchMove = (e: TouchEvent) => {
    const te = e.changedTouches[0].clientY;
    if (ts < te) {
      e.preventDefault();
    }
  };

  function handleDragStart() {
    document.documentElement.addEventListener('touchstart', onTouchStart, { passive: false });
    document.documentElement.addEventListener('touchmove', onTouchMove, { passive: false });
  }

  // Функция изменяющая порядок элементов в нашем массиве products
  function handleDragEnd(event) {
    document.documentElement.removeEventListener('touchstart', onTouchStart);
    document.documentElement.removeEventListener('touchmove', onTouchMove);
    const { active, over } = event;
    let newArr: IProduct[] = [];
    if (active.id !== over.id) {
      const dragEl = products.find(el => el.id === active.id);
      const dropEl = products.find(el => el.id === over.id);

      const oldIndex = products.indexOf(dragEl);
      const newIndex = products.indexOf(dropEl);

      newArr = arrayMove(products, oldIndex, newIndex);
      onReorderCallback(newArr)
    }
  }


  return (
    <div className={css.productsListWrapper}>
      {notationText && (
        <div className={css.notation}>
          <Icon name={'info'} />
          <p>{notationText}</p>
        </div>
      )}
      <div className={css.grid}>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
        >
          <SortableContext items={products} strategy={verticalListSortingStrategy}>
            {products.map((el, i) => {
              return (
                <ProductsCardSortable
                  isThird={enabledThirdIndices.includes(i)}
                  isActive={false}
                  key={el.id}
                  product={el as unknown as IProduct} // todo fixme
                />
              );
            })}
          </SortableContext>
        </DndContext>

      </div>
    </div>
  );
});

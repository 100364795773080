import { ChangeEvent, FC, memo, useCallback } from 'react';
import { Step } from 'src/components/Step/Step';
import { Input } from 'src/components/UI/Input/Input';
import { useStyle } from 'src/utils/theme/useStyle';
import { ImageLoader } from 'src/components/UI/ImageLoader/ImageLoader';
import { WizardShopNameRules } from './WizardShopName.style';
import { useTranslation } from '../../../utils/i18n/hooks/useTranslation';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { setShopDescription, setShopName, wizardStateSelector } from '../../../redux/slices/wizardSlice';

interface IWizardShopName {
  setShopLogo: (shopLogo: File) => void;
}

const logoSize = 512;

export const WizardShopName: FC<IWizardShopName> = memo(function WizardShopName(props) {
  const { setShopLogo } = props;
  const {
    wizard: {
      shopName,
      shopDescription,
      shopLogoUrl,
    },
  } = useAppSelector(wizardStateSelector);
  const { css } = useStyle(WizardShopNameRules);

  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const onChangeName = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setShopName(event.target.value));
  }, [dispatch]);

  const onChangeDescription = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setShopDescription(event.target.value));
  }, [dispatch]);

  const onChangeFile = (file: File) => {
    setShopLogo(file);
  };

  return (
    <Step
      stepNumber={1}
      title={t('wizardStepName.storeNameAndLogo')}
      subtitle={t('wizardStepName.willBeShownOnTelegram')}
      paddingBottom={0}
    >
      <ImageLoader
        onChangeFile={onChangeFile}
        imageUrl={shopLogoUrl}
        name={'shop-avatar'}
        propsStyles={{ width: 100, height: 100, borderRadius: '50%' }}
        extend={css.imagePicker}
        message={t('wizardStepName.minimumResolution', `${logoSize}×${logoSize}`)}
        checkSize={{
          maxWidth: logoSize,
          maxHeight: logoSize,
        }}
      />
      <div className={css.fieldWrapper}>
        <Input
          name="shop-name"
          type="text"
          controlled={true}
          value={shopName}
          label={t('wizardStepName.name')}
          placeholder={t('wizardStepName.enterStoreName')}
          labelTextTransform={'uppercase'}
          onChange={onChangeName}
        />
      </div>
      <div className={css.fieldWrapper}>
        <Input
          name="shop-description"
          type="text"
          controlled={true}
          value={shopDescription}
          label={t('wizardStepName.description')}
          placeholder={t('wizardStepName.forExampleAClothingStore')}
          labelTextTransform={'uppercase'}
          onChange={onChangeDescription}
          letterCounter={true}
          maxLength={24}
        />
      </div>
    </Step>
  );
});

import { FC, memo, useEffect } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { NoSettings } from 'src/components/NoSettings/NoSettings';
import { Layout } from 'src/components/Layout/Layout';
import Container from 'src/components/Container/Container';
import Footer from 'src/components/Footer/Footer';
import { Button } from 'src/components/UI/Button/Button';
import Text from 'src/components/UI/Text/Text';
import { SelectionsPageRules } from './SelectionsPage.style';
import useLoader from 'src/hooks/useLoader';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import { useNavigate } from 'react-router-dom';
import useBackButton from 'src/hooks/useBackButton';
import { SelectionCard } from 'src/blocks/settings-main/setting-selection/SelectionCard/SelectionCard';
import { selectionsState } from 'src/redux/slices/selectionsSlice';
import { getSelections } from 'src/redux/api/selections/getSelections';
import { NetworkStatus } from 'src/utils/network/network.constant';
import { useTranslation } from 'src/utils/i18n/hooks/useTranslation';
import { RoutePath } from 'src/routing/routeConfig';
import ModalError from 'src/components/ModalError/ModalError';
import { useModalState } from 'src/hooks/useModalState';


interface IProps {
  test?: string;
}

export const SelectionsPage: FC<IProps> = memo(function SelectionsPage(props) {
  const {} = props;
  const { css } = useStyle(SelectionsPageRules);
  const { networkStatus, selections } = useAppSelector(selectionsState);
  const { showLoader, hideLoader } = useLoader();
  const BackButton = useBackButton();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [renderErrorModal, activeErrorModal, closeErrorModal, openModal] = useModalState();

  useEffect(() => {
    BackButton.onClickCustom(goBack);
    function goBack() {
      navigate(-1);
    }
    return () => {
      BackButton?.offClickCustom(goBack);
    };
  }, [BackButton, navigate]);

  useEffect(() => {
    if (networkStatus === NetworkStatus.None) {
      dispatch(getSelections());
    }
    if (networkStatus === NetworkStatus.Loading) {
      showLoader();
    }
    if (networkStatus === NetworkStatus.Done) {
      hideLoader();
    }
    if (networkStatus === NetworkStatus.Failed) {
      openModal()
    }
  }, [networkStatus, dispatch, hideLoader, showLoader, openModal]);


  return (
    <Layout
      padding={'6px 0 0'}
      header={
        <header className={`${css.header} header`}>
          <Text
            text={t('selectionsPage.selections')}
            mod="title"
            fontWeight={800}
            fontSize={20}
            lineHeight={22}
          />
        </header>
      }
      footer={
        <Footer>
          <Button
            text={t('selectionsPage.createSelection')}
            propsStyles={{ width: '100%' }}
            href={RoutePath.CreateSelection}
          />
        </Footer>
      }
    >
      <Container extend={css.container}>
        {selections.length > 0 ? (
          <div className={css.textBlocksWrapper}>
            {selections.map(el => (
              <SelectionCard selectionData={el} key={el.uuid}/>
            ))}
          </div>
        ) : (
          <NoSettings
            title={t('selectionsPage.youHaventCreatedAnyCollectionsYet')}
            text={t('selectionsPage.createCollectionsFromYourProductsWeWillPlaceThemOnTheStoresHomepage')}
            icon="selections-large"
          />
        )}
      </Container>
      {renderErrorModal && (
        <ModalError
          onClose={closeErrorModal}
          title={'Get selections errot'}
          active={activeErrorModal}
        />
      )}
    </Layout>
  );
});

import { IRuleFn } from 'src/utils/theme/fela.interface';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { padding } from 'src/utils/applyCSSProperty ';

export interface IModalQrCodeRuleKeys {
  content: {};
  footer: {};
  canvasWrapper: {};
  shopLogo: {};
  shopLogoWrp: {};
  shopName: {};
  shopDescription: {};
  shareButton: {};
}

export type IModalQrCodeRules = IRuleFn<IModalQrCodeRuleKeys>;

const ModalQrCodeRuleFn: IModalQrCodeRules = props => {
  const { colorWhite, colorGrayLight } = props.theme;
  return {
    content: {
      ...padding(79, 0),
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      gap: 28,
      paddingBottom: 16,
      alignItems: 'center',
    },
    shareButton: {
      flexShrink: 0,
    },
    footer: {
      display: 'flex',
      columnGap: '12px',
      background: colorWhite,
      position: 'relative',
      marginTop: 36,
      paddingBottom: 32, 
      '::before': {
        position: 'absolute',
        content: '""',
        width: 'calc(100% + 40px)',
        height: 'calc(100% + 40px)',
        top: 0,
        left: 0,
        transform: 'translate(-10px, -12px)',
        background: colorWhite,
      }
    },
    canvasWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      background: colorWhite,
      position: 'relative',
      borderRadius: 16,
      ...padding(30, 32),
      '& canvas': {
        width: '200px!important',
        height: '200px!important',
        objectFit: 'contain'
      }
    },
    shopLogo: {
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      objectFit: 'cover',
    },
    shopLogoWrp: {
      width: 60,
      height: 60,
      position: 'absolute',
      top: 0,
      left: '50%',
      transform: 'translate(-50%, -65%)'
    },
    shopName: {
      fontWeight: 700,
      fontSize: 20,
      marginTop: 10,
      lineHeight: '22px',
    },
    shopDescription: {
      color: colorGrayLight,
      fontSize: 14,
      lineHeight: '18px',
      fontWeight: 500,
    } 
  };
};

export const modalQrCodeRules = createCachedStyle(ModalQrCodeRuleFn);

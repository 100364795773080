import { ChangeEvent, FC, memo, useCallback } from 'react';
import { Step } from 'src/components/Step/Step';
import { useStyle } from 'src/utils/theme/useStyle';
import { OptionCard } from 'src/components/OptionCard/OptionCard';
import { WizardDeliveryTypeRules } from './wizardOrderMethod.style';
import { DeliveryType } from '@teleport/schemas-protobuf';
import { useTranslation } from '../../../utils/i18n/hooks/useTranslation';
import { setDeliveryType, wizardStateSelector } from '../../../redux/slices/wizardSlice';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';

export const WizardDeliveryType: FC = memo(function WizardDeliveryType() {
  const { wizard: { deliveryType } } = useAppSelector(wizardStateSelector);
  const { css } = useStyle(WizardDeliveryTypeRules);

  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const onChangeMethod = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const value = Number(event.target.value);
    dispatch(setDeliveryType(value));
  }, [dispatch]);

  const isSelected = value => {
    return deliveryType === value;
  };

  return (
    <Step
      stepNumber={4}
      title={t('wizardStepOrderMethod.chooseDeliveryAndOrderType')}
      subtitle={t('wizardStepOrderMethod.theWayYouDeliverAndTheWayCustomerOrders')}
    >
      <div className={css.wrapper}>
        <OptionCard
          name="order-method"
          title={t('wizardStepOrderMethod.showcase')}
          text={t(
            'wizardStepOrderMethod.ifYouWantToDisplayProductsAndRedirectCustomerToYourWebsiteToPurchase',
          )}
          type="radio"
          value={`${DeliveryType.SHOWCASE}`}
          checked={isSelected(DeliveryType.SHOWCASE)}
          onChange={onChangeMethod}
        />
        <OptionCard
          name="order-method"
          title={t('wizardStepOrderMethod.request')}
          value={`${DeliveryType.REQUEST}`}
          text={t(
            'wizardStepOrderMethod.ifYouWantToGetCustomerContactsAndCommunicateFurtherYourself',
          )}
          type="radio"
          checked={isSelected(DeliveryType.REQUEST)}
          onChange={onChangeMethod}
        />
      </div>
    </Step>
  );
});

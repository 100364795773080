import { FC, memo, useCallback, useEffect } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { CreateModifierPageRules } from './CreateModifierPage.style';
import { Layout } from 'src/components/Layout/Layout';
import Text from 'src/components/UI/Text/Text';
import Container from 'src/components/Container/Container';
import useBackButton from 'src/hooks/useBackButton';
import { useNavigate } from 'react-router-dom';
import { useScrollToTop } from 'src/hooks/useScrollToTop';
import useLoader from 'src/hooks/useLoader';
import { useTranslation } from 'src/utils/i18n/hooks/useTranslation';
import { CreateUpdateModifier } from 'src/components/CreateUpdateModifier/CreateUpdateModifier';
import { RoutePath } from '../../routing/routeConfig';

//Page to display create Modifier
export const CreateModifierPage: FC = memo(function CreateModifierPage(props) {
  const {} = props;
  const { css } = useStyle(CreateModifierPageRules);
  const BackButton = useBackButton();
  const navigate = useNavigate();
  const { hideLoader } = useLoader();

  const { t } = useTranslation()

  useEffect(() => {
    hideLoader();
  }, [hideLoader]);

  useScrollToTop();
  useEffect(() => {
    BackButton.onClickCustom(goBack);
    function goBack() {
      navigate(-1);
    }
    return () => {
      BackButton?.offClickCustom(goBack);
    };
  }, [BackButton, navigate]);

  const saveModifierCallBack = useCallback(() => {
    navigate(RoutePath.Modifiers);
  }, [navigate])

  return (
    <Layout
      header={
        <header className={`${css.header} header`}>
          <Text text={t('createModifierPage.modifier')} mod="title" fontWeight={800} fontSize={20} lineHeight={22} />
        </header>
      }
    >
      <Container >
        <CreateUpdateModifier saveModifierCallBack={saveModifierCallBack} />
      </Container>
    </Layout>
  );
});

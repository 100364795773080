import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface IInputKeys {
  top: {};
  inputWrapper: {};
  input: {};
  symbol: {};
  message: {};
  iconBtn: {};
}

interface IInputProps {
  backgroundColor?: string;
  disabled: boolean;
  isInvaild: boolean;
  hasSymbol: boolean;
}

export type IInputRules = IRuleFn<IInputKeys, IInputProps>;

const inputRuleFn: IInputRules = props => {
  const { colorWhite, colorRgbBlack, mainColor, colorRed } = props.theme;
  const { backgroundColor, isInvaild, disabled, hasSymbol } = props;

  const bgColor = backgroundColor ? backgroundColor : colorWhite;

  return {
    top: {
      marginBottom: 8,
      display: 'flex',
      justifyContent: 'space-between',
    },

    inputWrapper: {
      position: 'relative',
    },

    input: {
      opacity: disabled ? .5 : 1,
      width: '100%',
      borderRadius: 12,
      paddingTop: 20,
      paddingBottom: '20px',
      paddingLeft: '16px',
      paddingRight: hasSymbol ? '32px' : '16px',
      outline: 'none',
      backgroundColor: bgColor,
      ':-webkit-autofill': {
        WebkitBoxShadow: `inset 0 0 0 100px ${bgColor}`,
      },
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: isInvaild ? colorRed : 'transparent',
      position: 'relative',
      // transform: 'translate3d(0)',

      // txt
      fontSize: 16,
      lineHeight: '20px',
      fontWeight: 400,
      letterSpacing: -0.4,
      caretColor: mainColor,
    },

    message: {
      marginTop: 4,
      color: isInvaild ? colorRed : `rgba(${colorRgbBlack}, .4)`,
      fontWeight: '500',
      lineHeight: '14px',
      fontSize: 12,

      '& a': {
        fontWeight: 600,
        color: mainColor,
      },
    },

    symbol: {
      position: 'absolute',
      top: '50%',
      right: '16px',
      transform: 'translateY(-50%)',
    },
    iconBtn: {
      position: 'absolute',
      top: '50%',
      right: '16px',
      transform: 'translateY(-50%)',
    },
  };
};

export const inputRules = createCachedStyle(inputRuleFn);

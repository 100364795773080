import { forwardRef, memo, ReactNode, useCallback, useEffect, useState } from 'react';
import { layoutRules } from './layout.style';
import { useStyle } from '../../utils/theme/useStyle';
import { useModalState } from 'src/hooks/useModalState';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import { wizardStateSelector } from 'src/redux/slices/wizardSlice';
import { NetworkStatus } from 'src/utils/network/network.constant';
import ModalError from '../ModalError/ModalError';
import { useTranslation } from '../../utils/i18n/hooks/useTranslation';
import { closeErrorModal, modalErrorState } from '../../redux/slices/ModalErrorSlice';

interface IErrorData {
  title?: string;
  text?: string;
  triggers: NetworkStatus[];
}

interface ILayout {
  children: ReactNode;
  header?: ReactNode;
  footer?: ReactNode;
  mainFlexColumn?: boolean;
  padding?: string;
  position?: 'fixed';
  top?: number;
  left?: number;
  zIndex?: number;
  errorData?: IErrorData;
}
export const Layout = memo(
  forwardRef<HTMLDivElement, ILayout>(function Layout(props, ref) {
    const { t } = useTranslation();
    const [contentError, setContentError] = useState({
      title: t('layout.error'),
      text: ''
    });
    const {
      children,
      header,
      footer,
      padding,
      top,
      left,
      position,
      zIndex,
      errorData,
    } = props;
    const dispatch = useAppDispatch();

    // // Telegram scroll fix !!!
    useEffect(() => {
      const overflow = 1;
      function setupDocument() {
        document.body.style.overflowY = 'hidden';
        document.body.style.marginTop = `${overflow}px`;
        document.body.style.height = window.innerHeight + overflow + 'px';
        document.body.style.paddingBottom = `${overflow}px`;
        // смещаем окно так, чтобы body оказался в верхнем левом углу
        window.scrollTo(0, overflow);
        // setTimeout(() => {
        //   document.body.style.marginTop = '';
        //   document.body.style.paddingBottom = '';
        //   window.scrollTo(0, 0);
        // }, 100)
      }

      const onScroll = () => {
        window.scrollTo(0, overflow);
      }

      setupDocument();
      window.addEventListener('focusout', onScroll);
      return () => {
        window.removeEventListener('focusout', onScroll);
      }
    }, []);

    const { css } = useStyle(layoutRules, {
      mainFlexColumn: props.mainFlexColumn,
      padding,
      position,
      top,
      left,
      zIndex,
    });
    const { saveStateError, saveNetworkStatus } = useAppSelector(wizardStateSelector);
    const { modalErrorIsOpen, errorTitle, errorText } = useAppSelector(modalErrorState);
    const [renderModal, activeModal, openModal, closeModal] = useModalState();
    const [errorDataState, setErrorDataState] = useState<IErrorData>({
      triggers: [],
    });

    useEffect(() => {
      if (errorData) {
        setErrorDataState(errorData)
      }
    }, [errorData]);

    // TODO Fix me TaskID - 86c14rj02
    const customCloseModal = useCallback(() => {
      dispatch(closeErrorModal());
      closeModal();
    }, [dispatch, closeModal]);

    useEffect(() => {
      if (saveNetworkStatus === NetworkStatus.Failed || errorDataState.triggers.find(el => el === NetworkStatus.Failed)) {
        openModal();
      }
    }, [
      // eslint-disable-next-line react-hooks/exhaustive-deps
      ...errorDataState.triggers,
      errorDataState,
      saveStateError,
      openModal,
      closeModal,
      saveNetworkStatus
    ]);

    useEffect(() => {
      if(modalErrorIsOpen) {
        setContentError({
          title: errorTitle,
          text: errorText
        })
        openModal();
      }
    }, [
      openModal,
      modalErrorIsOpen,
      errorTitle,
      errorText
    ]);


    // TODO Навести порядок с модальным окном ошибки TaskID - 86c14rj02
    useEffect(() => {
      if(saveNetworkStatus === NetworkStatus.Failed) {
        setContentError({ title: t('failedToUpdateTheStore'), text: `${t('layout.error')}: ${saveStateError}`});
      }
      else if(errorDataState.triggers.find(el => el === NetworkStatus.Failed)) {
        setContentError({
          title: errorDataState.title || t('layout.error'),
          text: errorDataState.text || '',
        });
      }
    }, [t, saveNetworkStatus, saveStateError, errorDataState]);

    return (
      <div className={`${css.root} root-layout`} ref={ref}>
        {header}
        <main className={css.main}>{children}</main>
        {footer}
        {renderModal && (
          <ModalError
            onClose={customCloseModal}
            title={contentError.title}
            text={contentError.text}
            active={activeModal}
          />
        )}
      </div>
    );
  }),
);

import { FC, memo, useRef } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import Modal, { ModalNames } from 'src/components/Modal/Modal';
import Text from 'src/components/UI/Text/Text';
import { modalModifierTypeRules } from './ModalModifierVariants.style';
import { Input } from '../UI/Input/Input';
import { useTheme } from 'src/utils/theme/useTheme';
import { Button } from '../UI/Button/Button';
import { useTranslation } from 'src/utils/i18n/hooks/useTranslation';
import { IModifierVariantOmit } from 'src/redux/slices/modifiersSlice';
import { useScrollModal } from '../../hooks/useScrollModal';

interface IProps {
  active: boolean;
  onClose: () => void;
  variantsArr: IModifierVariantOmit[];
  setVariantsArr: React.Dispatch<
    React.SetStateAction<IModifierVariantOmit[]>
  >;
}

export const ModalModifierVariants: FC<IProps> = memo(function ModalModifierVariants(props) {
  const { active, onClose, variantsArr, setVariantsArr } = props;
  const { theme } = useTheme();

  const modalInnerRef = useRef<HTMLDivElement>(null);
  useScrollModal(modalInnerRef, variantsArr);

  const { t } = useTranslation()

  const modalHeaderRef = useRef<HTMLDivElement>(null);
  const modalFooterRef = useRef<HTMLDivElement>(null);
  const { css } = useStyle(modalModifierTypeRules, {
    headerHeight: modalHeaderRef.current?.clientHeight,
    footerHeight: modalFooterRef.current?.clientHeight,
  });

  const deleteVariant = (id: string | number) => {
    setVariantsArr(variantsArr.filter((_, i) => `${i}` !== `${id}`))
  }

  const addMoreFunction = () => {
    setVariantsArr([...variantsArr, { uuid: '', title: '' }]);
  }

  return (
    <Modal
      name={ModalNames.ModifierVariants}
      active={active}
      propsStyles={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}
      onClose={onClose}
      // expand={true}
    >
      <div ref={modalHeaderRef} className={css.header}>
        <Text text={t('modalModifierVariants.options')} mod="title" />
      </div>
      <div className={css.modalInner} ref={modalInnerRef}>
        {variantsArr.length > 0 && variantsArr.map((el, i) => {
          return (
            <Input
              id={`${i}`}
              key={i}
              iconBtn={i !== 0 ? 'delete' : undefined}
              controlled={true}
              onIconBtnClick={i !== 0 && deleteVariant}
              name="variant"
              type="text"
              placeholder={t('modalModifierVariants.enterValue')}
              maxLength={70}
              value={el.title}
              onChange={e => {
                setVariantsArr( variantsArr.map((variant, index) => {
                  if (index === i) {
                    return {
                      ...variant,
                      title: e.target.value,
                    };
                  } else {
                    return variant;
                  }
                }),);
              }}
              propsStyles={{ backgroundColor: theme.background }}
            />
          );
        })}
        <Button
          text={t('modalModifierVariants.addMore')}
          hasGradient={false}
          propsStyles={{
            width: '100%',
            background: theme.colorGrey,
            color: theme.colorBlack
          }}
          onClick={addMoreFunction}
        />
      </div>
      <footer className={css.footer} ref={modalFooterRef}>
        <div className={css.inputWrp}>
          <Button
            text={t('modalModifierVariants.save')}
            propsStyles={{
              width: '100%',
            }}
            onClick={onClose}
          />
        </div>
      </footer>
    </Modal>
  );
});

import { FC, memo, useEffect, useState } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { Layout } from 'src/components/Layout/Layout';
import Footer from 'src/components/Footer/Footer';
import { Button } from 'src/components/UI/Button/Button';
import Text from 'src/components/UI/Text/Text';
import Container from 'src/components/Container/Container';
import useBackButton from 'src/hooks/useBackButton';
import { useNavigate, useParams } from 'react-router-dom';
import { useScrollToTop } from 'src/hooks/useScrollToTop';
import useLoader from 'src/hooks/useLoader';
import { Input } from 'src/components/UI/Input/Input';
import { useTheme } from 'src/utils/theme/useTheme';
import {
  categoryState,
  deleteRootCategory,
  editRootCategory,
  getRootCategoryList,
} from 'src/redux/slices/categorySlice';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import { RoutePath } from '../../routing/routeConfig';
import { SettingEditCategoryPageRules } from './SettingEditCategory.style';
import { useModalState } from 'src/hooks/useModalState';
import ModalConfirm from 'src/components/ModalConfirm/ModalConfirm';
import { useTranslation } from 'src/utils/i18n/hooks/useTranslation';
import { NetworkStatus } from 'src/utils/network/network.constant';

//СТРАНИЦА РЕДАКТИРОВАНИЯ КАТЕГОРИИ ИЛИ ПОДКАТЕГОРИИ
export const SettingEditCategoryPage: FC = memo(function SettingEditCategoryPage(props) {
  const {} = props;
  const { css } = useStyle(SettingEditCategoryPageRules);
  const BackButton = useBackButton();
  const navigate = useNavigate();
  const { hideLoader } = useLoader();
  const { theme } = useTheme();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { categories, networkStatus } = useAppSelector(categoryState);
  const [renderModal, activeModal, openModal, closeModal] = useModalState();

  const { t } = useTranslation()

  useEffect(() => {
    if (networkStatus === NetworkStatus.None) {
      dispatch(getRootCategoryList());
    }
    hideLoader();
  }, [hideLoader, networkStatus, dispatch]);

  useScrollToTop();
  useEffect(() => {
    BackButton.onClickCustom(goBack);
    function goBack() {
      navigate(-1);
    }
    return () => {
      BackButton?.offClickCustom(goBack);
    };
  }, [BackButton, navigate]);

  const [categoryToSend, setCategoryToSend] = useState({
    title: '',
    subcategory: false,
  });
  
  useEffect(() => {
    const currentCategory = categories.find(obj => obj.uuid === id);

    if (currentCategory)
      setCategoryToSend({
        title: currentCategory.title,
        subcategory: false,
      });
  }, [categories, id]);

  const sendCategory = () => {
    if (categoryToSend)
      dispatch(
        editRootCategory({
          uuid: id,
          title: categoryToSend.title,
        }),
      );
    navigate(RoutePath.Categories);
  };

  const deleteCategoryFn = () => {
    if (categoryToSend) dispatch(deleteRootCategory(id));
    navigate(RoutePath.Categories);
  };

  return (
    <Layout
      header={
        <header className={`${css.header} header`}>
          <Text text={t('settingEditCategoryPage.category')} mod="title" fontWeight={800} fontSize={20} lineHeight={22} />
        </header>
      }
      footer={
        <Footer>
          <Button
            disabled={!categoryToSend.title}
            text={t('settingEditCategoryPage.saveCategory')}
            propsStyles={{ width: '100%' }}
            onClick={sendCategory}
          />
        </Footer>
      }
    >
      <Container extend={css.container}>
        <div className={css.grid}>
          <Input
            name="category-name"
            type="text"
            label={t('settingEditCategoryPage.name')}
            controlled={true}
            value={categoryToSend.title}
            placeholder={t('settingEditCategoryPage.enterCategoryName')}
            propsStyles={{ backgroundColor: theme.colorWhite }}
            onChange={e =>
              setCategoryToSend({
                ...categoryToSend,
                title: e.target.value,
              })
            }
          />
        </div>
        <div className={css.deleteButtonWrapper}>
          <Button
            hasGradient={false}
            disabled={!categoryToSend.title}
            text={t('settingEditCategoryPage.deleteCategory')}
            propsStyles={{ width: '100%', background: theme.colorGrey, color: theme.colorBlack }}
            onClick={openModal}
          />
        </div>
      </Container>
      {renderModal && (
        <ModalConfirm
          title={t('settingEditCategoryPage.deleteCategoryQuestion')}
          active={activeModal}
          onClose={closeModal}
          confirmAction={deleteCategoryFn}
        />
      )}
    </Layout>
  );
});

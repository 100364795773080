import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { NetworkStatus } from 'src/utils/network/network.constant';
import { getStories } from '../api/stories/getStories';
import { IStory } from 'src/types/stories';
import { createStory } from '../api/stories/createStory';
import { editStory } from '../api/stories/editStory';
import { deleteStory } from '../api/stories/deleteStory';

interface IStoriesSlice {
  stories: IStory[];
  networkStatus: NetworkStatus;
  upsertNetworkStatus:  NetworkStatus;
  deleteNetworkStatus:  NetworkStatus;
}

const initialState: IStoriesSlice = {
  stories: [],
  networkStatus: NetworkStatus.None,
  upsertNetworkStatus: NetworkStatus.None,
  deleteNetworkStatus: NetworkStatus.None
};

export const storiesSlice = createSlice({
  name: 'Stories',
  initialState,
  reducers: {
    setStories: (state, { payload }: PayloadAction<IStory[]>) => {
      state.stories = payload;
    },
    deleteStoryById: (state, { payload }: PayloadAction<string>) => {
      state.stories = state.stories.filter(el => el.id !== payload);
    },
    resetNetworkStatus: (
      state,
      { payload }: PayloadAction<'networkStatus' | 'upsertNetworkStatus' | 'deleteNetworkStatus'>,
    ) => {
      state[payload] = NetworkStatus.None;
    },
  },
  extraReducers: builder => {
    builder.addCase(getStories.pending, state => {
      state.networkStatus = NetworkStatus.Loading;
    });
    builder.addCase(getStories.fulfilled, (state, action) => {
      state.stories = action.payload;
      state.networkStatus = NetworkStatus.Done;
    });
    builder.addCase(getStories.rejected, state => {
      state.networkStatus = NetworkStatus.Failed;
    });
    builder.addCase(createStory.pending, state => {
      state.upsertNetworkStatus = NetworkStatus.Loading
    });
    builder.addCase(createStory.rejected, state => {
      state.upsertNetworkStatus = NetworkStatus.Failed;
    });
    builder.addCase(createStory.fulfilled, (state, action) => {
      state.stories.push(action.payload);
      state.upsertNetworkStatus = NetworkStatus.Done;
    });
    builder.addCase(editStory.pending, state => {
      state.upsertNetworkStatus = NetworkStatus.Loading;
    });
    builder.addCase(editStory.rejected, state => {
      state.upsertNetworkStatus = NetworkStatus.Failed;
    });
    builder.addCase(editStory.fulfilled, (state, action) => {
      const story = action.payload;
      const element = state.stories.find(el => el.id === story.id);
      const index = state.stories.indexOf(element);
      if (index !== -1) {
        state.stories.splice(index, 1, story);
      }
      state.upsertNetworkStatus = NetworkStatus.Done
    });

    builder.addCase(deleteStory.rejected, state => {
      state.deleteNetworkStatus = NetworkStatus.Failed;
    });
    builder.addCase(deleteStory.pending, state => {
      state.deleteNetworkStatus = NetworkStatus.Loading;
    });
    builder.addCase(deleteStory.fulfilled, state => {
      state.deleteNetworkStatus = NetworkStatus.Done;
    });
  },
});

export const { deleteStoryById, resetNetworkStatus, setStories } = storiesSlice.actions;

export const storiesState = (state: RootState) => state[storiesSlice.name];

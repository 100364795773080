import { IPromoCode } from '../../components/PromoCodeForm/PromoCodeForm';
import { OwnerPromocode } from '@teleport/schemas-protobuf/port/v1/port_promocode_pb';
import { TypeOfDiscount } from '../slices/promoCodesSlice';

export const PromoCodeTranslator = {
  toOwnerPromoCode(data: IPromoCode) {

    let impact;

    if (data.typeOfDiscount === TypeOfDiscount.PercentImpact) {
      impact = {
        value: {value: data.discountAmount},
        case: TypeOfDiscount.PercentImpact
      }
    } else {
      impact = {
        value: {value: BigInt(data.discountAmount)},
        case: TypeOfDiscount.PriceImpact
      }
    }

    return new OwnerPromocode({
      promocode: {
        impact: impact,
        uuid: data.uuid,
        codename: data.codeName,
      },
      enabled: data.isActive,
      constraintSet: {
        firstOrder: data.forFirstOrder,
        minimalPriceOrder: data.minOrderAmount ? BigInt(data.minOrderAmount) : BigInt(0),
        uniquePerUser: data.isDisposable,
      }
    })
  },
};

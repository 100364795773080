export const DEV_TELEGRAM_INIT = process.env['REACT_APP_DEV_TELEGRAM_INIT_DATA'];

export const standardColors = ['#3390ec', '#0500FF', '#A48B66', '#962E2E', '#226620'];

export const freeTrialPeriod = 14;

export const standardColorsNames = {
  '#3390ec': 'Голубой',
  '#0500FF': 'Синий',
  '#A48B66': 'Коричневый',
  '#962E2E': 'Красный',
  '#226620': 'Зеленый',
};

export const telegramStarsString = 'stars';

export const linkMaxLength = 255;

export const newSelectionPageName = 'newselection'

export const httpsProtocol = 'https://'

export const modifiersEnabledShops = ['tlprt099v','tlprt639g','tlprt806z','tlprt318x','tlprt357r','tlprt643g']

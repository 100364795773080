import { padding } from 'src/utils/applyCSSProperty ';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface ISelectionsPageKeys {
  wrapper: {};
  header: {};
  container: {};
  textBlocksWrapper: {};
}

// interface ISelectionsPageProps {}

export type ISelectionsPage = IRuleFn<ISelectionsPageKeys>;

const SelectionsPageRuleFn: ISelectionsPage = props => {
  const {} = props.theme;
  const {} = props;

  return {
    header: {
      ...padding(16),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    wrapper: {},
    container: {
      overflow: 'hidden',
    },
    textBlocksWrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: 8,
      paddingBottom: 8,
    },
  };
};

export const SelectionsPageRules = createCachedStyle(SelectionsPageRuleFn);

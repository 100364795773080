import { IRuleFn } from '../../utils/theme/fela.interface';
import { padding } from '../../utils/applyCSSProperty ';
import { createCachedStyle } from '../../utils/theme/createCachedStyle';

export interface IPromoCodeFormKeys {
  wrapperJustify: {};
  wrapper: {};
  header: {};
  footer: {};
  removeBtnWrapper: {};
  optionsWrapper: {};
}

interface IPromoCodeFormProps {
  footerHeight: number;
}

export type IPromoCodeForm = IRuleFn<IPromoCodeFormKeys, IPromoCodeFormProps>;

const PromoCodeFormRuleFn: IPromoCodeForm = props => {
  const { colorWhite, sidePadding } = props.theme;
  const { footerHeight } = props;

  return {
    wrapper: {
      paddingTop: 16,
      paddingBottom: footerHeight + 33,
      display: 'flex',
      flexDirection: 'column',
      rowGap: 24,
    },
    wrapperJustify: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    header: {
      paddingBottom: 6,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    footer: {
      position: 'fixed',
      backgroundColor: colorWhite,
      left: 0,
      bottom: 0,
      width: '100%',
      ...padding(12,sidePadding, 30),
    },
    removeBtnWrapper: {},
    optionsWrapper: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 8,
    }
  };
};

export const PromoCodeFormRules = createCachedStyle(PromoCodeFormRuleFn);

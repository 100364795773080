import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { Layout } from 'src/components/Layout/Layout';
import Text from 'src/components/UI/Text/Text';
import Container from 'src/components/Container/Container';
import { EditSelectionPageRules } from './EditSelectionPage.style';
import useLoader from 'src/hooks/useLoader';
import useBackButton from 'src/hooks/useBackButton';
import { useNavigate, useParams } from 'react-router-dom';
import { useModalState } from 'src/hooks/useModalState';
import ModalError from 'src/components/ModalError/ModalError';
import { useScrollViewport } from 'src/hooks/useScrollViewport';
import { useTranslation } from 'src/utils/i18n/hooks/useTranslation';
import { SelectionForm } from 'src/components/SelectionForm/SelectionForm';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import { selectionsState } from 'src/redux/slices/selectionsSlice';
import { getSelections } from 'src/redux/api/selections/getSelections';
import { NetworkStatus } from 'src/utils/network/network.constant';

export const EditSelectionPage: FC = memo(function EditSelectionPage(props) {
  const {} = props;
  const { css } = useStyle(EditSelectionPageRules);
  const BackButton = useBackButton();
  const { hideLoader, showLoader } = useLoader();
  const navigate = useNavigate();
  const dispatch = useAppDispatch()
  const { id } = useParams();
  const [renderErrorModal, activeErrorModal, closeErrorModal] = useModalState();

  const { networkStatus, selections } = useAppSelector(selectionsState);
  const selection = useMemo(() => {
    return selections.find(el => el.uuid === id);
  }, [selections, id])
  
  useEffect(() => {
    if (networkStatus === NetworkStatus.None) {
      dispatch(getSelections());
    }
    if (networkStatus === NetworkStatus.Loading) {
      showLoader();
    }
    if (networkStatus === NetworkStatus.Done) {
      hideLoader();
    }
  }, [networkStatus, dispatch, hideLoader, showLoader]);

  const [layoutEl, setLayoutEl] = useState({ current: null });

  const layoutRef = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      setLayoutEl({ current: node });
    }
  }, []);

  useScrollViewport({scrollElementRef: layoutEl});

  useEffect(() => {
    BackButton.onClickCustom(goBack);
    function goBack() {
      navigate(-1);
    }
    return () => {
      BackButton?.offClickCustom(goBack);
    };
  }, [BackButton, navigate]);

  const { t } = useTranslation()

  return (
    <>
      <Layout
        header={
          <header className={`${css.header} header`}>
            <Text text={t('modalSettingSelection.selection')} mod="title" fontWeight={800} fontSize={20} lineHeight={22} />
          </header>
        }
        ref={layoutRef}
      >
        <Container extend={css.container}>
          {selection && (
            <SelectionForm selection={selection} />
          )}
        </Container>
      </Layout>
      {renderErrorModal && (
        <ModalError
          onClose={closeErrorModal}
          title={'Ошибка хардкод'}
          text={'Текст ошибки хардкод'}
          active={activeErrorModal}
        />
      )}
    </>
  );
});

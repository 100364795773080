import { padding } from 'src/utils/applyCSSProperty ';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface ISelectionProductPageKeys {
  wrapper: {};
  header: {};
  container: {};
  textBlocksWrapper: {};
  notation: {};
}

export type ISelectionProductPage = IRuleFn<ISelectionProductPageKeys>;

const SelectionProductPageRuleFn: ISelectionProductPage = props => {
  const { colorBlack, colorDarkGrey } = props.theme;
  const {} = props;

  return {
    header: {
      ...padding(16),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    wrapper: {},
    container: {
      overflow: 'hidden',
      height: '100%',
    },
    textBlocksWrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: 8,
      paddingBottom: 8,
    },
    notation: {
      marginBottom: '12px',
      display: 'flex',
      gap: 8,
      alignItems: 'flex-start',
      color: colorDarkGrey,
      fontSize: 14,
      lineHeight: '16px',
      fontWeight: '600',
      '& svg': {
        fill: colorBlack,
      },
    },
  };
};

export const SelectionProductPageRules = createCachedStyle(SelectionProductPageRuleFn);

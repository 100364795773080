import { IRuleFn } from '../../utils/theme/fela.interface';
import { createCachedStyle } from '../../utils/theme/createCachedStyle';
import { padding } from '../../utils/applyCSSProperty ';

export interface IStatisticsCardKeys {
 card: {};
 title: {};
 value: {};
 percentages: {};
}
export interface IStatisticsPeriodProps {
  increase: boolean;
  delta: number;
}


export type IStatisticsCard = IRuleFn<IStatisticsCardKeys, IStatisticsPeriodProps>;

const StatisticsCardRuleFn: IStatisticsCard = props => {
  const { background, colorDarkGreen, colorRed } = props.theme;
  const { increase, delta } = props;

  let percentagesColor = colorDarkGreen;

  if (!increase) {
    percentagesColor = colorRed;
  }
  if (delta === 0) {
    percentagesColor = colorDarkGreen;
  }

  return {
    card: {
      ...padding(16, 12),
      backgroundColor: background,
      backdropFilter: 'blur(20px)',
      borderRadius: 12,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      overflow: 'hidden',
    },
    title: {
      minHeight: 36,
      marginBottom: 8,
    },
    value: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      marginBottom: 4,
    },
    percentages: {
      color: percentagesColor,
    },
  };
};

export const StatisticsCardRules = createCachedStyle(StatisticsCardRuleFn);

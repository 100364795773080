import { padding } from 'src/utils/applyCSSProperty ';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface IProductsPageKeys {
  header: {};
  container: {};
  productsWrapper: {};
  amountProducts: {};
  filteringMethods: {};
  pillsWrapper: {};
  noResults: {};
}

export type IProductsPage = IRuleFn<IProductsPageKeys>;

const ProductsPageRuleFn: IProductsPage = props => {
  const { colorDarkGrey, sidePadding } = props.theme;
  const {} = props;

  return {
    header: { ...padding(16), display: 'flex', columnGap: 8 },
    container: {
      overflow: 'hidden',
    },
    productsWrapper: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 8,
      paddingBottom: 8,
      paddingRight: 16,
    },
    amountProducts: {
      color: colorDarkGrey,
    },
    filteringMethods: {
      paddingTop: 12,
      paddingBottom: 12,
    },
    pillsWrapper: {
      maxWidth: '100%',
      overflow: 'scroll',
      padding: `12px ${sidePadding}px`,
      display: 'flex',
      alignItems: 'center',
      columnGap: '4px',

      '::-webkit-scrollbar': {
        display: 'none',
      },
    },
    noResults: {
      fontSize: 18,
      fontWeight: '400',
      textAlign: 'center',
      ...padding(20, 0),
    }
  };
};

export const ProductsPageRules = createCachedStyle(ProductsPageRuleFn);

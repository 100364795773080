import { padding } from 'src/utils/applyCSSProperty ';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface IModalSelectionFormKeys {
  header: {};
  footer: {};
  removeBtnWrapper: {};
  infoBtn: {};
  justifyWrapper: {};
  title: {};
  wrapper: {};
  info: {};
  form: {};
}

export type IModalSelectionForm = IRuleFn<
  IModalSelectionFormKeys>;

const ModalSelectionFormRuleFn: IModalSelectionForm = props => {
  const { sidePadding, colorWhite, colorBlack, colorRgbBlack, mainColor } = props.theme;
  const { } = props;

  return {
    form: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    header: {
      ...padding(10, sidePadding, 12),
    },
    footer: {
      position: 'fixed',
      backgroundColor: colorWhite,
      left: 0,
      bottom: 0,
      width: '100%',
      ...padding(12,sidePadding, 30),
    },
    removeBtnWrapper: {
      marginTop: 'auto',
      ...padding(8, 0),
    },
    infoBtn: {
      '& svg': {
        width: '100%',
        height: '100%',
        fill: colorBlack,
      },
    },
    title: {
      display: 'flex',
      alignItems: 'center',
      columnGap: 8,
    },
    justifyWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    wrapper: {
      ...padding(12, 0),
    },
    info: {
      marginTop: 4,
      fontWeight: 500,
      fontSize: 12,
      lineHeight: '16px',
      color: `rgba(${colorRgbBlack}, .4)`,

      '& a': {
        color: mainColor,
      },
    },
  };
};

export const ModalSelectionFormRules = createCachedStyle(ModalSelectionFormRuleFn);

import { useMemo } from 'react';
import { useTranslation } from '../i18n/hooks/useTranslation';
import { IListWithCardOption } from '../../components/UI/Lists/ListWithCards/ListWithCards';
import { Currency } from '@teleport/schemas-protobuf';

export const useCurrencyData = (): IListWithCardOption[] => {
  const { t } = useTranslation();

  return useMemo(() => {
    const cryptoOrder = [Currency[Currency.USDT], Currency[Currency.TON], Currency[Currency.BTC], Currency[Currency.NOT]];
    const allCurrencies = [
      {
        title: Currency[Currency.AED],
        subtitle: t('wizardStepEnd.unitedArabEmiratesDirham'),
        value: String(Currency.AED),
        symbol: 'د.إ',
      },
      {
        title: Currency[Currency.AMD],
        subtitle: t('wizardStepEnd.armenianDram'),
        value: String(Currency.AMD),
        symbol: '֏',
      },
      {
        title: Currency[Currency.ARS],
        subtitle: t('wizardStepEnd.argentinePeso'),
        value: String(Currency.ARS),
        symbol: '$',
      },
      {
        title: Currency[Currency.AZN],
        subtitle: t('wizardStepEnd.azerbaijanManat'),
        value: String(Currency.AZN),
        symbol: '₼',
      },
      {
        title: Currency[Currency.BDT],
        subtitle: t('wizardStepEnd.bangladeshiTaka'),
        value: String(Currency.BDT),
        symbol: 'টাকা',
      },
      {
        title: Currency[Currency.BRL],
        subtitle: t('wizardStepEnd.brazilianReal'),
        value: String(Currency.BRL),
        symbol: 'R$',
      },
      {
        title: Currency[Currency.BYN],
        subtitle: t('wizardStepEnd.belarusianRuble'),
        value: String(Currency.BYN),
        symbol: 'Br',
      },
      {
        title: Currency[Currency.COP],
        subtitle: t('wizardStepEnd.colombianPeso'),
        value: String(Currency.COP),
        symbol: 'Col$',
      },
      {
        title: Currency[Currency.EGP],
        subtitle: t('wizardStepEnd.egyptianPounds'),
        value: String(Currency.EGP),
        symbol: '£',
      },
      {
        title: Currency[Currency.ETB],
        subtitle: t('wizardStepEnd.ethiopianBirrs'),
        value: String(Currency.ETB),
        symbol: 'Br',
      },
      {
        title: Currency[Currency.EUR],
        subtitle: t('wizardStepEnd.euro'),
        value: String(Currency.EUR),
        symbol: '€',
      },
      {
        title: Currency[Currency.GEL],
        subtitle: t('wizardStepEnd.georgianLari'),
        value: String(Currency.GEL),
        symbol: '₾',
      },
      {
        title: Currency[Currency.IDR],
        subtitle: t('wizardStepEnd.indonesianRupiahs'),
        value: String(Currency.IDR),
        symbol: 'Rp',
      },
      {
        title: Currency[Currency.INR],
        subtitle: t('wizardStepEnd.indianRupee'),
        value: String(Currency.INR),
        symbol: '₹',
      },
      {
        title: Currency[Currency.KES],
        subtitle: t('wizardStepEnd.kenyanShilling'),
        value: String(Currency.KES),
        symbol: 'KSh',
      },
      {
        title: Currency[Currency.KGS],
        subtitle: t('wizardStepEnd.kyrgyzstanSom'),
        value: String(Currency.KGS),
        symbol: 'лв',
      },
      {
        title: Currency[Currency.KZT],
        subtitle: t('wizardStepEnd.kazakhstaniTenge'),
        value: String(Currency.KZT),
        symbol: '₸'
      },
      {
        title: Currency[Currency.LKR],
        subtitle: t('wizardStepEnd.sriLankanRupee'),
        value: String(Currency.LKR),
        symbol: 'Rs',
      },
      {
        title: Currency[Currency.NGN],
        subtitle: t('wizardStepEnd.nigerianNaira'),
        value: String(Currency.NGN),
        symbol: '₦',
      },
      {
        title: Currency[Currency.PHP],
        subtitle: t('wizardStepEnd.philippinePeso'),
        value: String(Currency.PHP),
        symbol: '₱',
      },
      {
        title: Currency[Currency.PKR],
        subtitle: t('wizardStepEnd.pakistaniRupee'),
        value: String(Currency.PKR),
        symbol: 'Rs',
      },
      {
        title: Currency[Currency.RUB],
        subtitle: t('wizardStepEnd.russianRubble'),
        value: String(Currency.RUB),
        symbol: '₽',
      },
      {
        title: Currency[Currency.THB],
        subtitle: t('wizardStepEnd.thaiBaht'),
        value: String(Currency.THB),
        symbol: '฿',
      },
      {
        title: Currency[Currency.TRY],
        subtitle: t('wizardStepEnd.turkishLira'),
        value: String(Currency.TRY),
        symbol: '₺',
      },
      {
        title: Currency[Currency.UAH],
        subtitle: t('wizardStepEnd.ukrainianHryvnia'),
        value: String(Currency.UAH),
        symbol: '₴',
      },
      {
        title: Currency[Currency.UGX],
        subtitle: t('wizardStepEnd.ugandanShilling'),
        value: String(Currency.UGX),
        symbol: '/=',
      },
      {
        title: Currency[Currency.USD],
        subtitle: t('wizardStepEnd.usDollar'),
        value: String(Currency.USD),
        symbol: '$',
      },
      {
        title: Currency[Currency.UZS],
        subtitle: t('wizardStepEnd.uzbekistaniSom'),
        value: String(Currency.UZS),
        symbol: 'sum',
      },
      {
        title: Currency[Currency.USDT],
        subtitle: t('wizardStepEnd.tetherUSDt'),
        value: String(Currency.USDT),
        symbol: 'USD₮',
      },
      {
        title: Currency[Currency.TON],
        subtitle: t('wizardStepEnd.toncoin'),
        value: String(Currency.TON),
        symbol: 'TON',
      },
      {
        title: Currency[Currency.BTC],
        subtitle: t('wizardStepEnd.bitcoin'),
        value: String(Currency.BTC),
        symbol: '₿',
      },
      {
        title: Currency[Currency.NOT],
        subtitle: t('wizardStepEnd.notcoin'),
        value: String(Currency.NOT),
        symbol: 'NOT',
      },
      {
        title: Currency[Currency.PLN],
        subtitle: t('wizardStepEnd.polishZloty'),
        value: String(Currency.PLN),
        symbol: 'zł',
      },
      {
        title: t('wizardStepEnd.telegramStars'),
        value: String(Currency.XTR),
        symbol: 'stars',
      },
    ]

    const fiat = allCurrencies
      .filter(currency => !cryptoOrder.includes(currency.title) && currency.symbol !== 'stars')
      .sort((a, b) => a.title.localeCompare(b.title))

    const crypto = cryptoOrder
      .map(order => allCurrencies.find(currency => currency.title === order))
      .filter(Boolean) as typeof allCurrencies;

    const stars = allCurrencies.find(currency => currency.symbol === 'stars');

    return [...fiat, ...crypto, stars].filter(Boolean);
  }, [t]);
};

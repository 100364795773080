import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { createAppAsyncThunk } from '../../hooks/redux';
import { Modifier, ModifierUpsertRequest, ModifierVariant } from '@teleport/schemas-protobuf';
import { NetworkStatus } from '../../utils/network/network.constant';

interface IInitialState {
  showModifiers: boolean;
  modifiers: Modifier[];
  networkStatus: NetworkStatus;
  networkUpsertStatus: NetworkStatus;
  networkDeleteStatus: NetworkStatus;
  errorMessageUpsert?: string;
}

export type IModifierOmit = Omit<Modifier, 'fromBinary' | 'fromJson' | 'fromJsonString' | 'equals' | 'clone' | 'toBinary' | 'toJson' | 'toJsonString' | 'getType'>
export type IModifierVariantOmit = Omit<ModifierVariant, 'fromBinary' | 'fromJson' | 'fromJsonString' | 'equals' | 'clone' | 'toBinary' | 'toJson' | 'toJsonString' | 'getType'>

export const getModifiersList = createAppAsyncThunk(
  'modifiers/getModifiersList',
  async (_, thunkAPI) => {
    const result = await thunkAPI.extra.portClientApi().modifierList({});
    return result.modifiers
  },
);

export const createUpdateModifier = createAppAsyncThunk(
  'modifiers/createUpdateModifier',
  async (data: IModifierOmit, thunkAPI) => {
    const modifierForRequest = new ModifierUpsertRequest({modifier: data})
    const result = await thunkAPI.extra.portOwnerApi().modifierUpsert(modifierForRequest);
    return new Modifier({
      ...data,
      uuid: result.modifierUuid,
    })
  },
);

export const deleteModifier = createAppAsyncThunk(
  'modifiers/deleteModifier',
  async (uuid: string, thunkAPI) => {
    await thunkAPI.extra.portOwnerApi().modifierDelete({modifierUuid: uuid})
    return uuid
  }
)

// products services courses events
const initialState: IInitialState = {
  showModifiers: false,
  modifiers: [],
  networkStatus: NetworkStatus.None,
  networkUpsertStatus: NetworkStatus.None,
  networkDeleteStatus: NetworkStatus.None,
  errorMessageUpsert: undefined
};
export const modifiersSlice = createSlice({
  name: 'Modifiers',
  initialState,
  reducers: {
    setShowModifiers: (state, action: PayloadAction<boolean>) => {
      state.showModifiers = action.payload;
    },
    clearErrorMessage: state => {
      state.errorMessageUpsert = undefined
    },
    resetNetworkStatus: (
      state,
      { payload }: PayloadAction<'networkStatus' | 'networkUpsertStatus' | 'networkDeleteStatus'>,
    ) => {
      state[payload] = NetworkStatus.None;
    },
  },
  extraReducers: builder => {
    builder.addCase(getModifiersList.pending, state => {
      state.networkStatus = NetworkStatus.Loading;
    });
    builder.addCase(getModifiersList.rejected, state => {
      state.networkStatus = NetworkStatus.Failed;
    });
    builder.addCase(getModifiersList.fulfilled, (state, action) => {
      state.networkStatus = NetworkStatus.Done;
      state.modifiers = action.payload;
    });
    builder.addCase(createUpdateModifier.pending, state => {
      state.errorMessageUpsert = undefined
      state.networkUpsertStatus = NetworkStatus.Loading;
    });
    builder.addCase(createUpdateModifier.rejected, (state, action) => {
      const errorMessage = action.error.message
      if (errorMessage) state.errorMessageUpsert = errorMessage
      state.networkUpsertStatus = NetworkStatus.Failed;
    });
    builder.addCase(createUpdateModifier.fulfilled, (state, action) => {
      state.networkUpsertStatus = NetworkStatus.Done;
      const modifier = action.payload;
      const modifierExists = state.modifiers.find(m => m.uuid === modifier.uuid);
      if (!modifierExists) {
        state.modifiers.push(modifier);
      }
    });
    builder.addCase(deleteModifier.pending, state => {
      state.errorMessageUpsert = undefined
      state.networkDeleteStatus = NetworkStatus.Loading;
    });
    builder.addCase(deleteModifier.rejected, (state, action) => {
      const errorMessage = action.error.message
      if (errorMessage) state.errorMessageUpsert = errorMessage
      state.networkDeleteStatus = NetworkStatus.Failed;
    });
    builder.addCase(deleteModifier.fulfilled, (state, action) => {
      state.networkDeleteStatus = NetworkStatus.Done;
      state.modifiers = state.modifiers.filter(modifier => modifier.uuid !== action.payload )
    });
  }
});

export const { setShowModifiers, clearErrorMessage, resetNetworkStatus } = modifiersSlice.actions;

export const modifiersSelector = (state: RootState) => state[modifiersSlice.name];

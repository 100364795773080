import { padding } from 'src/utils/applyCSSProperty ';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface IModalListProductsRuleKeys {
  header: {};
  modalInner: {};
  searchLine: {};
  backButton: {};
  pillsWrapper: {};
  productsWrapper: {};
  spinnerWrapper: {};
  footer: {};
  noResults: {};
}

interface IModalListProductsRuleProps {
  headerHeight?: number;
}

export type IModalListProductsRules = IRuleFn<
  IModalListProductsRuleKeys,
  IModalListProductsRuleProps
>;

const ModalListProductsRuleFn: IModalListProductsRules = props => {
  const { colorWhite, sidePadding } = props.theme;
  const { headerHeight } = props;

  return {
    header: {
      paddingTop: 20,
    },
    searchLine: {
      padding: `0 ${sidePadding}px`,
      display: 'flex',
      columnGap: 8,
    },
    modalInner: {
      overflow: 'auto',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
      maxHeight: `calc(100% - ${headerHeight}px)`,
    },
    pillsWrapper: {
      overflowX: 'auto',
      padding: `12px ${sidePadding}px`,
      display: 'flex',
      alignItems: 'center',
      columnGap: '4px',

      '::-webkit-scrollbar': {
        display: 'none',
      },
    },

    productsWrapper: {
      display: 'flex',
      rowGap: 8,
      padding: `0 ${sidePadding}px 12px`,
      flexDirection: 'column',
    },

    spinnerWrapper: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },

    backButton: {
      flexShrink: 0,
      '& svg': {
        fill: colorWhite,
      },
    },
    footer: {
      position: 'sticky',
      backgroundColor: colorWhite,
      bottom: 0,
      ...padding(12,sidePadding, 30),
    },
    noResults: {
      fontSize: 18,
      fontWeight: '400',
      textAlign: 'center',
      ...padding(20, 0),
    }
  };
};

export const ModalListProductsRules = createCachedStyle(ModalListProductsRuleFn);

import React, { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useStyle } from '../../../../../utils/theme/useStyle';
import { ModalModifierRules } from './ModalModifiers.style';
import Modal, { ModalNames } from '../../../../../components/Modal/Modal';
import Text from '../../../../../components/UI/Text/Text';
import { Button } from '../../../../../components/UI/Button/Button';
import { useTheme } from '../../../../../utils/theme/useTheme';
import {
  SettingProductModifier
} from '../SettingProductModifier/SettingProductModifier';
import { Icon } from '../../../../../components/UI/Icon/Icon';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux';
import { getModifiersList, modifiersSelector } from '../../../../../redux/slices/modifiersSlice';
import { Modifier } from '@teleport/schemas-protobuf';
import { NetworkStatus } from '../../../../../utils/network/network.constant';
import { Spinner } from '../../../../../components/UI/Spinner/Spinner';
import {
  productSelector,
  resetArrayForSettingModifiers,
  saveModifiersProduct
} from '../../../../../redux/slices/productSlice';
import { CreateUpdateModifier } from '../../../../../components/CreateUpdateModifier/CreateUpdateModifier';
import { useScrollModal } from '../../../../../hooks/useScrollModal';
import { useTranslation } from '../../../../../utils/i18n/hooks/useTranslation';

interface IProps {
  active: boolean;
  onClose: () => void;
}

export const ModalModifiers: FC<IProps> = memo(function ModalModifier(props) {
  const {active, onClose} = props;
  const modalHeaderRef = useRef<HTMLDivElement>(null);
  const { theme } = useTheme();
  const { css } = useStyle(ModalModifierRules, {
    headerHeight: modalHeaderRef.current?.clientHeight,
  });
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { modifiers, networkUpsertStatus, networkStatus } = useAppSelector(modifiersSelector);
  const { modifiers: productModifiers } = useAppSelector(productSelector);
  const closeModal = useCallback(() => {
    dispatch(resetArrayForSettingModifiers());
    onClose();
  }, [dispatch, onClose])
  const modalInnerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    dispatch(getModifiersList());
  }, [dispatch]);

  useEffect(() => {
    if (networkUpsertStatus === NetworkStatus.Done) {
      dispatch(getModifiersList());
    }
  }, [dispatch, networkUpsertStatus]);

  useEffect(() => {
    if (networkStatus === NetworkStatus.Failed) {
      closeModal();
    }
  }, [networkStatus, closeModal]);
  
  const [selectedModifier, setSelectedModifier] = useState<Modifier | null>(null);
  const [creatingModifier, setCreatingModifier] = useState<boolean>(false);
  const [showLinkField, setShowLinkField] = useState(false);
  const showLinkFieldCallBack = useCallback((state: boolean) => {
    setShowLinkField(state);
  }, [])
  const [modalTitle, setModalTitle] = useState('');

  useEffect(() => {
    if(selectedModifier) {
      setModalTitle(selectedModifier.title)
    }
    else if (creatingModifier) {
      setModalTitle(t('modalModifiers.modifier'))
    }
    else {
      setModalTitle(t('modalModifiers.productModifiers'))
    }
  }, [t, selectedModifier, creatingModifier]);

  useScrollModal(modalInnerRef, creatingModifier, selectedModifier, showLinkField);

  const onClickModifier = useCallback((event:  React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const modifierId = event.currentTarget.id;
    const modifier = modifiers.find(el => el.uuid === modifierId)
    setSelectedModifier(modifier)
  }, [modifiers]);

  const onClickBackButton = useCallback(() => {
    if(selectedModifier) {
      setSelectedModifier(null);
      dispatch(resetArrayForSettingModifiers());
    }
    else if (creatingModifier) {
      setCreatingModifier(false);
    }
  }, [
    dispatch,
    creatingModifier,
    selectedModifier
  ]);

  const createModifier = () => {
    setCreatingModifier(true);
  }

  const saveSettingModifier = () => {
    dispatch(saveModifiersProduct());
    dispatch(resetArrayForSettingModifiers());
    setSelectedModifier(null);
  }

  const saveModifierCallBack = useCallback(() => {
    setCreatingModifier(false);
  }, [])

  const content = useMemo(() => {
    if (networkStatus === NetworkStatus.Loading) {
      return <div className={css.wrapper}><Spinner /></div>
    }
    else if (networkStatus === NetworkStatus.Failed) {
      return undefined;
    }
    else if (creatingModifier) {
      return <CreateUpdateModifier
        paddingBottom={0}
        inputBackgroundColor={theme.background}
        showLinkFieldCallBack={showLinkFieldCallBack}
        saveModifierCallBack={saveModifierCallBack}
      />
    }
    else if (selectedModifier) {
      return <SettingProductModifier modifier={selectedModifier} endSetting={onClickBackButton} />
    }
    else if (modifiers.length > 0) {
      const activeElements = [];
      return <div className={css.modifiersWrapper}>
        {modifiers.map(el => {
          activeElements.length = 0;
          productModifiers.map(modifier => {
            const activeModifierVariant = el.variants.find(item => item.uuid === modifier.modifierVariantUuid)
            if(activeModifierVariant) {
              activeElements.push(activeModifierVariant.title)
            }
          })
          return (
            <button
              className={`${css.buttonModifier} ${activeElements.length > 0? css.btnActive : ''}`}
              key={el.uuid}
              id={el.uuid}
              onClick={onClickModifier}
            >
              <Text mod="title" text={el.title} fontSize={16} lineHeight={18} fontWeight={700}/>
              {activeElements.length > 0
                  && <div className={css.activeModifiers}>
                    {activeElements.join(', ')}
                  </div>
              }
            </button>
          )
        }
        )}
      </div>
    } else {
      return <div className={css.noModifiers}>
        <Icon name='modifier-large' properties={{className: css.modifiersIcon}} />
        <Text
          mod='text'
          text={t('modalModifiers.youHaventCreatedAnyModifiersYet')}
          fontSize={16}
          lineHeight={18}
          fontWeight={700}
        />
        <Text
          mod='text'
          text={t('modalModifiers.createModifiersAndUsersWillBeAbleToSelectProductsBySizeColorAndOtherCharacteristics')}
          fontSize={14}
          lineHeight={16}
          fontWeight={600}
          extend={css.subtitle}
        />
      </div>
    }
  }, [
    t,
    css,
    theme,
    onClickBackButton,
    productModifiers,
    creatingModifier,
    modifiers,
    networkStatus,
    onClickModifier,
    selectedModifier,
    saveModifierCallBack,
    showLinkFieldCallBack,
  ])

  return <Modal
    name={ModalNames.SettingsSelection}
    active={active}
    propsStyles={{paddingLeft: 0, paddingRight: 0, paddingBottom: 0}}
    onClose={closeModal}
  >
    <div ref={modalHeaderRef} className={css.header}>
      {(selectedModifier || creatingModifier)
        && <button
          className={css.backButton}
          onClick={onClickBackButton}
        >
          <Icon name='arrow-left'/>
        </button>
      }
      <Text
        text={modalTitle}
        mod="title"
      />
    </div>
    <div className={css.modalInner} ref={modalInnerRef}>
      {content}
      {!creatingModifier
        && <div className={css.footer}>
          <Button
            text={selectedModifier ? t('modalModifiers.save') : t('modalModifiers.createModifier')}
            hasGradient={false}
            type="submit"
            onClick={selectedModifier ? saveSettingModifier : createModifier}
            propsStyles={{
              width: '100%',
              background: selectedModifier ? theme.mainColor : theme.colorGrey,
              color: selectedModifier ? theme.colorWhite : theme.colorBlack
            }}
          />
        </div>}
    </div>
  </Modal>;
});

import { IRuleFn } from 'src/utils/theme/fela.interface';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { margin, padding } from 'src/utils/applyCSSProperty ';

export interface ICreateUpdateModifierRuleKeys {
  wrapper: {};
  justifyWrapper: {};
  grid: {};
  templateWrapper: {};
  inputNotation: {};
  footer: {};
  form: {};
  dellButtonWrapper: {};
}

interface IProductFormProps {
  footerHeight: number;
  paddingBottom: number;
}

export type ICreateUpdateModifierRules = IRuleFn<ICreateUpdateModifierRuleKeys, IProductFormProps>;

const togglerRuleFn: ICreateUpdateModifierRules = props => {
  const { mainColor, colorBlack, colorRgbBlack, colorWhite, sidePadding } = props.theme;
  const { footerHeight, paddingBottom } = props;
  return {
    form: {
      height: '100%'
    },
    dellButtonWrapper: {
      marginTop: 'auto',
    },
    wrapper: {
      overflow: 'hidden',
      paddingTop: 14,
      paddingBottom: paddingBottom ?? `${footerHeight + 33}px`,
      display: 'flex',
      flexDirection: 'column',
      gap: 10,
      height: '100%'
    },
    justifyWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      ...padding(12, 0),
    },
    grid: {
      display: 'flex',
      flexDirection: 'column',
      gap: 24,
    },
    templateWrapper: {
      ...margin(40, 0),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      borderWidth: '1px',
      borderRadius: 8,
      borderStyle: 'solid',
      borderColor: colorBlack,
      backgroundColor: mainColor,
    },
    inputNotation: {
      marginTop: '4px',
      fontWeight: '500',
      fontSize: 12,
      lineHeight: '16px',
      letterSpacing: '-0.4px',
      color: `rgba(${colorRgbBlack}, .4)`,
      '& span': {
        color: mainColor,
      },
    },
    footer: {
      position: 'fixed',
      backgroundColor: colorWhite,
      left: 0,
      bottom: 0,
      width: '100%',
      ...padding(12,sidePadding, 30),
    },
  };
};

export const createUpdateModifierRules = createCachedStyle(togglerRuleFn);

import { FC, memo } from 'react';
import Text from 'src/components/UI/Text/Text';
import { useStyle } from 'src/utils/theme/useStyle';
import { ModifierCardRules } from './ModifierCard.style';
import { generatePath, useNavigate } from 'react-router-dom';
import { RoutePath } from '../../routing/routeConfig';
import { Modifier } from '@teleport/schemas-protobuf';
import { useModifierTypeList } from 'src/hooks/useModifierTypeList';
import { useTranslation } from 'src/utils/i18n/hooks/useTranslation';

interface IProps {
  modifierData: Modifier;
}

export const ModifierCard: FC<IProps> = memo(function ModifierCard(props) {
  const { modifierData } = props;
  const { uuid, title } = modifierData;
  const { css } = useStyle(ModifierCardRules);
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(generatePath(RoutePath.EditModifier, {id: uuid}));
  };

  const modifierType = useModifierTypeList().find(type => type.type === modifierData.uiType)?.title
  const { t } = useTranslation()

  return (
    <div className={css.card} id={`${uuid}`} onClick={handleClick}>
      <Text mod="title" text={title} fontSize={14} lineHeight={18} />      
      <p className={css.text}>
        <span>{modifierType ?? ''}</span>
        {modifierData.affectsPrice &&  <>
          {'  ·  '}
          <span>{t('modifierCard.affectsThePrice')}</span>
        </>}
      </p>
    </div>
  );
});
